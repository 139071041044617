@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --popup-max-height: 99%;

    @include mobile {
        --popup-content-padding: 21px;
    }

    @include desktop {
        --popup-content-padding: 85px;
        --popup-min-width: 610px;
        --popup-max-width: 610px;
    }
}

.Popup {
    @include mobile {
        display: block;
    }

    &-Content {
        border-radius: 9px;
        padding: 64px var(--popup-content-padding) 36px;
        max-width: var(--popup-max-width);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    }

    &-Header {
        position: static;

        @include mobile {
            display: block;
        }
    }

    &-Heading {
        text-align: center;
        margin: 0;
        letter-spacing: 0.5px;
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
    }

    & &-CloseBtn {
        inset-block-start: 28px;
        inset-inline-end: 28px;
    }

    .Form {
        text-align: center;
        margin-top: 48px;

        .Button[type='submit'] {
            width: 100%;
            max-width: unset;
            margin-top: 30px;
        }
    }

    .NotificationList {
        width: 100%;
    }
}

.scrollDisabled {
    @include desktop {
        overflow-y: hidden;
    }
}
