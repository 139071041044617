@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.Header > .Menu-MenuWrapper,
.Header .Menu-Overlay {
    // stylelint-disable-next-line
    max-width: unset !important;
}

:root {
    --menu-item-height: 62px;
    --menu-total-height: 62px;
    --menu-offset-mobile: var(--main-offset-mobile);
    --menu-max-height: auto;
}

.Menu-MenuWrapper {
    min-height: var(--menu-total-height);
}

[dir='ltr'] .Menu {
    &-ExpandedState {
        @include mobile-and-tablet {
            display: none;
            right: var(--menu-offset-mobile);
        }
    }

    &-CategoriesBlockTitle {
        color: $black;

        &:hover {
            color: $black;
            cursor: pointer;
        }

        @include mobile-and-tablet {
            display: none;
        }

        @include above-narrow-desktop {
            font-weight: 500;
            font-size: 17px;
            line-height: 20px;
            letter-spacing: 0.8px;
            display: flex;
            align-items: center;
            margin-bottom: 5px;
        }
    }

    &-ItemCaption {
        @include mobile {
            margin-left: var(--menu-offset-mobile);
            margin-right: var(--menu-offset-mobile);
        }
    }

    &-Item {
        @include tablet {
            height: auto;
        }

        .Menu-ItemCaption {
            @include mobile-and-tablet {
                display: flex;
                align-items: center;
                margin: 0;
                letter-spacing: 0.2px;
                color: $black;
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                border-top: 1px solid var(--main-text-and-icons-bg);
                min-height: 60px;
                padding: 0 var(--menu-offset-mobile);

                > img {
                    max-width: 25px;
                    margin-right: 10px;
                }
            }

            &_isExpanded {
                @include mobile-and-tablet {
                    display: none !important;
                }
            }
        }

        &_isNestedOpen {
            .Menu-ItemCaption {
                + .Menu-SubMenu .Menu-SubItemWrapper {
                    @include mobile-and-tablet {
                        display: none;
                    }
                }

                &.Menu-ItemCaption_isSecondLevel {
                    @include mobile-and-tablet {
                        display: none;
                    }

                    + .Menu-SubMenu {
                        .Menu-SubItemWrapper {
                            @include mobile-and-tablet {
                                display: block;
                            }
                        }

                        .Menu-ItemCaption_isAllLabel {
                            @include mobile-and-tablet {
                                display: flex;
                            }
                        }
                    }
                }
            }
        }
    }

    &-AdditionalContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        padding: 44px 0;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            letter-spacing: 0.2px;
            color: $black;
            min-width: 100px;
            width: 33.33%;
            height: 100px;
            border: 1px solid var(--main-text-and-icons-bg);
            border-radius: 10px;
            padding: 20px;

            svg {
                margin-bottom: 10px;
            }

            &:first-child {
                svg {
                    min-width: 18px;
                    min-height: 23px;
                }
            }
        }
    }

    &-MenuWrapper {
        background: $grey;

        @include desktop {
            margin-top: 0;
        }

        &_isOverlayVisible {
            @include desktop {
                border-radius: 0px 0px 20px 20px;
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
                -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
                transition: all 0.3s ease-in-out;
                transition-delay: 0.1s;
            }
        }

        &_isMenuPage {
            background: none;

            .Menu {
                &-MainCategories {
                    .Menu-ItemList,
                    .Menu-Item {
                        display: block;
                    }

                    .Menu-ItemList {
                        padding-left: 0;
                        padding-right: 0;
                    }

                    .Menu-Item {
                        border-bottom: 1px solid var(--main-text-and-icons-bg);
                    }

                    .Menu-ItemCaption_type_main {
                        flex-direction: row;
                        justify-content: start;
                        color: $black;
                        text-transform: none;
                        font-size: 14px;
                        font-weight: 600;
                        padding: 0 var(--menu-offset-mobile);

                        img {
                            margin-right: 20px;
                            margin-bottom: 0;
                        }
                    }

                    .Menu-ExpandedState {
                        display: block;
                    }
                }
            }
        }
    }

    &-MainCategories,
    &-SubCategoriesWrapper > div {
        @include desktop {
            width: 100%;
        }

        @include above-narrow-desktop {
            max-width: var(--content-wrapper-width);
            margin: 0 auto;
            padding-inline: 32px;
        }

        @include wide-desktop {
            padding-inline: 20px;
        }
    }

    &-MainCategories {
        .Menu-ItemList,
        .Menu-Item {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .Menu-Item,
        .Menu-Link {
            @include above-narrow-desktop {
                line-height: var(--menu-item-height);
            }
        }

        .Menu-ItemList {
            @include mobile-and-tablet {
                gap: 20px;
                overflow-x: auto;
                white-space: nowrap;
                padding-left: 18px;
                padding-right: 18px;
            }
        }

        .Menu-Item {
            @include mobile-and-tablet {
                margin: 0;
            }
        }

        .Menu-Link {
            @include tablet {
                font-weight: 400;
            }

            @include above-narrow-desktop {
                font-weight: 700;

                &:hover {
                    color: var(--main-text-and-icons_2);

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        display: block;
                        background: var(--main-primary);
                        height: 3.5px;
                        width: 100%;
                    }
                }
            }
        }

        .Menu-ItemCaption {
            &_type_main {
                display: flex;
                align-items: center;
                justify-content: center;

                @include mobile-and-tablet {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    letter-spacing: 0.2px;
                    color: #3E3D3D;
                    align-items: center;
                    border: 0;
                    flex-direction: column;
                    text-wrap: nowrap;
                    padding: 9px 0;
                }

                @include above-narrow-desktop {
                    margin: 0;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    letter-spacing: 2px;
                    color: var(--main-text-and-icons_2);
                }

                img {
                    width: auto;

                    @include mobile-and-tablet {
                        margin-right: 0;
                        margin-bottom: 8px;
                    }

                    @include wide-desktop {
                        display: block;
                        margin-right: 8px;
                    }
                }
            }

            &_isAllLabel {
                @include mobile-and-tablet {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    letter-spacing: 0.2px;
                    text-align: center;
                    color: $black;
                    background-color: $grey;
                    justify-content: center;
                }
            }

            &_withBorder {
                @include mobile-and-tablet {
                    border-top: 1px solid var(--main-text-and-icons-bg)
                }
            }
        }
    }

    &-Overlay {
        opacity: 0;
        pointer-events: none;
        z-index: -1;
        position: fixed;
        background-color: var(--popup-background);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100%;
        top: var(--menu-max-height);
        max-width: unset;

        @include default-transition();

        &_isVisible {
            pointer-events: all;
            opacity: 1;
        }
    }

    &-SubCategoriesWrapper {
        backface-visibility: hidden;
        height: var(--menu-max-height);
        max-height: 0;
        overflow: hidden;
        position: absolute;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        transition: max-height 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
        transition-delay: 0.1s;
        width: 100%;
        z-index: 1;
        background: $white;
        margin: 0;

        > div {
            padding: 30px 20px;
        }

        &_isVisible {
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
            border-radius: 0 0 20px 20px;
            max-height: var(--menu-max-height);
        }
    }

    &-SubMenu {
        @include tablet {
            display: none;
            margin-block-end: 28px;
        }

        @include above-narrow-desktop {
            margin: 0;
            min-height: 430px;
        }

        &_isVisible {
            @include mobile-and-tablet {
                display: block;
                padding: 0;
                background-color: $white;
                margin-bottom: 0;
            }
        }

        > .Menu-ItemList {
            @include tablet {
                columns: unset;
            }

            @include above-narrow-desktop {
                columns: unset;
                display: grid;
                grid-template-columns: 2fr repeat(3, 1fr);
            }

            .Menu-ItemList_categoriesBlock {
                > div {
                    @include above-narrow-desktop {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                    }
                }
            }

            &_withExtraBlock {
                @include above-narrow-desktop {
                    padding: 0 14px;
                    grid-auto-flow: column;
                    grid-template-columns: 2fr repeat(4, 1fr);
                }

                @include wide-desktop {
                    padding: 0;
                }

                .Menu-ItemList_extraBlock {
                    display: none;

                    @include above-narrow-desktop {
                        display: flex;
                        padding: 0 20px;
                        margin-top: 40px;

                        &::after {
                            content: '';
                            border-left: 0.5px solid var(--main-text-and-icons-bg);
                            display: block;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            width: 0.5px;
                            height: 362px;
                        }
                    }
                }

                .Menu-ItemList {
                    &:last-child {
                        grid-column: span 6;
                    }

                    &_categoriesBlock {
                        > div {
                            @include above-narrow-desktop {
                                grid-template-columns: repeat(2, 1fr);
                            }
                        }
                    }
                }
            }
        }
    }

    &-SubCategories {
        @include desktop {
            margin: 0;
            padding: 0;
        }

        .Menu-ItemCaption {
            @include desktop {
                font-weight: 300;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.6px;
                color: $black;
                padding: 0;
                margin: 16px 0 0;
            }

            &_isAllLabel {
                @include desktop {
                    font-size: 12px;
                    font-weight: 400;
                    color: var(--primary-base-color);
                    text-decoration: underline;
                }
            }
        }

        .ProductCard {
            max-width: 200px;
            border-right: 1px solid transparent;

            &-FigureReview, &-Figure {
                padding-top: 0;
                padding-inline: 0;
            }

            &-Content {
                padding-inline: 0;
            }

            &:hover {
                @include desktop {
                    border: 1px solid transparent;
                    border-right: 1px solid transparent;
                    box-shadow: none;

                    .ProductCard-VisibleOnHover {
                        display: none;
                    }
                }
            }
        }
    }

    &-ItemBanner {
        a {
            display: block;
        }
    }

    &-Sub {
        &ItemWrapper {
            @include above-narrow-desktop {
                break-inside: unset;
                overflow: unset;
            }

            &_hasChildren {
                height: 100%;
                padding: 0 20px;

                &:last-of-type {
                    padding-right: 0;
                }

                .Menu-SubLevelDesktop {
                    margin: 20px 0 0;

                    &::after {
                        content: '';
                        border-left: 0.5px solid var(--main-text-and-icons-bg);
                        display: block;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: -20px;
                        width: 0.5px;
                        height: 362px;
                    }
                }

                > .Menu-Link > .Menu-ItemCaption {
                    @include desktop {
                        font-weight: 500;
                        font-size: 17px;
                        line-height: 20px;
                        letter-spacing: 0.8px;
                        display: inline-flex;
                        align-items: center;
                        margin-top: 0;
                        width: auto;

                        img {
                            max-width: 40px;
                            order: 2;
                            position: absolute;
                            right: -40px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }
            }

            &_hasCustomClass {
                &_grey-bg {
                    .Menu-SubLevelDesktop {
                        background-color: $grey;
                        border-radius: 9px;
                        padding: 13px;

                        .Menu-Link {
                            &:first-of-type .Menu-ItemCaption {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    &-ItemList {
        &_type_subcategory {
            padding-right: 5px;

            @include mobile {
                padding: 0;
            }
        }
    }

    &-Link {
        margin: 0;

        @include mobile {
            display: block;
        }

        &_withShowMore {
            @include desktop {
                display: block;
                grid-column: 1/3;
                text-align: right;
            }
        }
    }
}
