@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.InstallPrompt {
    --install-prompt-bottom: -72px;

    position: fixed;
    inset-block-end: 0;
    inset-inline: 0;
    z-index: 20;
    border-block-start: 1px solid transparent;

    @include desktop {
        width: 100%;
        max-width: 400px;
    }

    @include mobile {
        transition: transform 200ms cubic-bezier(.47, 0, .745, .715);
        transform: translateY(var(--install-prompt-bottom));

        .hideOnScroll & {
            transform: inherit;
        }

        + .CmsPage {
            margin-block-start: 0;

            .CmsPage-Wrapper {
                margin-block-start: 0;
            }
        }
    }
}
