@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.Map {
    display: flex;
    flex-direction: column;

    @include desktop {
        height: 500px;
        flex-direction: row;
    }

    &-Wrapper {
        width: calc(100% + 30px);
        height: 400px;
        right: -15px;
        left: -15px;

        @include desktop {
            height: 100%;
            width: 70%;
            right: 0;
            left: 0;
        }
    }

    .Marker {
        &-Marker {
            left: -19px;
            top: -46px;
            cursor: pointer;
        }

        &-Hint {
            display: none;
            padding: 28px 39px;
            position: absolute;
            width: 200px;
            bottom: calc(100% + 76px);
            left: -100px;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            background: $white;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.150131);
            border-radius: 10px;
            z-index: 2;

            &::before {
                content: '';
                position: absolute;
                width: 50%;
                height: 10px;
                right: 0;
                bottom: 0;
                left: 25%;
                background: $white;
                z-index: 2;
            }

            &::after {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                bottom: -20px;
                left: 85px;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-top: 20px solid $white;
                filter: drop-shadow(0 2px 10px rgba(0, 0, 0, 0.150131));
            }

            &_isExpanded {
                display: block;
            }

            &Close {
                position: absolute;
                top: 4px;
                right: 3px;
                padding: 8px 11px;
                cursor: pointer;
            }

            .AdditionalInfo {
                color: #DD151E;
            }
        }
    }
}
