@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.ProductLabel {
    background: $white;
    text-transform: uppercase;
    padding: 2px 10px;
    display: inline-block;
    width: auto;
    letter-spacing: 0.2px;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    border-radius: 100px;
    text-align: center;
    color: var(--other-checkout_elements_and_icons);
    white-space: nowrap;

    @include mobile {
        font-size: 10px;
        padding: 2px 5px;
    }

    @include hoverable {
        &:hover {
            color: var(--other-checkout_elements_and_icons);
        }
    }

    &_type_default {
        background: transparent;
    }

    &_type_discount,
    &_type_sale {
        background: var(--main-primary);
        border-color: var(--main-primary);
        font-size: 11px;
        line-height: 16px;
        color: $white;

        @include hoverable {
            &:hover {
                color: $white;
            }
        }
    }

    &_type_default,
    &_type_new,
    &_type_preview,
    &_type_recommended {
        border: 1px solid transparent;
    }

    &_type_new {
        background: rgba(72, 71, 255, 0.2);
    }

    &_type_preview {
        background: rgba(255, 199, 0, 0.4);
    }

    &_type_recommended {
        background: rgba(234, 62, 159, 0.2);
    }

    &_type_reprint {
        background: rgba(59, 187, 118, 0.2);
    }

    &_type_in_a_warehouse {
        color: $white;
        background: $default-green-color;
        padding-left: 30px;

        svg {
            position: absolute;
            left: 5px;
            top: 50%;
            transform: translateY(-50%);

            path {
                fill: $white;
            }
        }

        @include hoverable {
            &:hover {
                color: $white;
            }
        }
    }

    &_type_renewal {
        border-color: var(--product-labels-resumption);
    }


    &_type_ebook,
    &_type_audiobook {
        background: $white;
        border: 1px solid $violet;
        color: $violet;

        @include hoverable {
            &:hover {
                color: $violet;
            }
        }
    }

    &_type_only_here {
        background: rgba($default-primary-base-color, 0.2);
        color: $default-primary-base-color;

        @include hoverable {
            &:hover {
                color: $default-primary-base-color;
            }
        }
    }


    &_type_in_stock,
    &_type_top,
    &_type_top_wide,
    &_type_top_with_number {
        color: $white;

        @include hoverable {
            &:hover {
                color: $white;
            }
        }
    }

    &_type_in_stock {
        background: var(--product-labels-resumption);
        border-radius: 9px 5px 5px 0;
        border-color: var(--product-labels-resumption);
        padding: 3.5px 8px;
    }

    &_type_top,
    &_type_top_wide,
    &_type_top_with_number {
        background: var(--product-labels-top);
    }

    &_type_top {
        padding: 2.5px 8px;
    }

    &_type_top_wide {
        padding: 2px 30px;
    }

    &-Wrapper {
        &_type_top_with_number {
            display: flex;
        }

        &_type_top_with_number span,
        .ProductLabel_type_number {
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--main-primary);
            color: $white;
            border-radius: 100px;
            font-weight: 700;
            font-size: 11px;
            line-height: 11px;
            min-width: 21px;
            height: 21px;
            padding: 2px 5px;

            @include hoverable {
                &:hover {
                    color: $white;
                }
            }
        }

        &_type_top_with_number_secondary span,
        .ProductLabel_type_number_secondary {
            background: var(--main-icons);
        }

        &_type_top_with_number span {
            margin-left: 5px;
        }
    }

    &_isLink {
        cursor: pointer;
    }
}
