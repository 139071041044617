@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.HomeWidgets {
    margin: 0 auto;
    padding-block-start: 24px;
    padding-inline: 20px;
    width: 100%;
    max-width: var(--content-wrapper-width);
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    @include mobile {
        padding-inline: 0;
        padding-block-start: 5px;
        grid-gap: 2px;
    }

    .slick-track {
        display: flex;
        min-width: 100%;
    }

    .slick-slide {
        height: inherit;

        & > div, & > div > div, & > div > div > div {
            height: 100%;
        }
    }

    .slick-dots {
        bottom: 12px;

        li {
            &, & button, & button::before {
                height: 8px;
                width: 8px;
            }
        }
    }

    &-Main,
    &-Mid,
    &-Bottom {
        @include slickSlideBetween(10px);

        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        overflow: hidden;

        &Slide {
            display: grid;
            grid-template-columns: repeat(1, minmax(0, 1fr));
            grid-gap: 20px;

            &Content {
                display: grid;
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
        }

        &_hasAdditionalText {
            grid-template-columns: repeat(1, minmax(0, 1fr)) repeat(1, minmax(0, 295px));
            grid-gap: 20px;

            @include mobile {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }

            .CmsBlock-Wrapper {
                .mgz-child:not(:last-child) > .mgz-element-inner {
                    margin-bottom: 0;
                }

                svg {
                    height: 24px;
                    width: 24px;
                    margin-top: -4px;
                }

                & > .mgz-element {
                    height: 100%;

                    & > .mgz-element-inner {
                        height: 100%;

                        & > .mgz-container {
                            height: 100%;

                            & > .mgz-element {
                                height: 100%;
                            }
                        }
                    }
                }

                .mgz-element-row {
                    & + .mgz-element-row {
                        margin-top: 32px;
                    }

                    .mgz-element-heading {
                        margin: 0 0 6px;
                    }
                }

                h2,
                h3,
                h4,
                h5,
                h6 {
                    margin: 0;
                    font-family: $font-poppins;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 17px;
                    text-align: left;
                    color: $black;

                    &::after {
                        content: "";
                        display: block;
                        width: 50px;
                        height: 2px;
                        background: $default-primary-base-color;
                        margin-top: 6px;
                    }
                }

                li {
                    list-style: none;

                    & + li {
                        margin-top: 6px;
                    }
                }

                a {
                    font-family: $font-poppins;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 20px;
                    text-align: left;
                    color: $black;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &-Main {
        @include mobile {
            grid-gap: 0;
        }
    }

    &-Mid {
        @include mobile {
            @include slickSlideBetween(1px);
        }
    }

    &-Bottom {
        @include slickSlideBetween(16px);

        .HomeWidgets-Image {
            aspect-ratio: 1/1;
        }

        @include mobile {
            padding: 0 16px 0 5px;
            margin-top: 14px;

            @include slickSlideBetween(8px);

            .slick-slider {
                margin-right: -16px;
            }

            .slick-list {
                overflow-y: hidden;
                overflow-x: scroll;
                scroll-behavior: auto;
            }
        }
    }

    &-BottomTitle {
        @include show-text-in-x-lines(2);

        height: 32px;
        margin: 12px 0 0;
        font-family: $font-poppins;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        text-align: center;
    }

    &-Image {
        display: flex;
        overflow: hidden;
        border-radius: 10px;
        object-fit: contain;
        object-position: center;

        @include mobile {
            border-radius: 0;
        }
    }

    &-Navigation {
        &Button {
            z-index: 50;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            &_forBottom {
                top: calc(50% - 18px);
            }

            &_prev {
                left: 10px;

                &.HomeWidgets-NavigationButton_forBottom {
                    left: 0;
                }
            }

            &_next {
                right: 10px;

                &.HomeWidgets-NavigationButton_forBottom {
                    right: 0;
                }

                &.HomeWidgets-NavigationButton_withAdditionalText {
                    @include desktop {
                        right: 325px;
                    }

                    @include tablet {
                        right: 325px;
                    }
                }
            }
        }
    }
}

