@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.ProductReviewRating {
    @include mobile {
        align-items: center;
    }
    
    &-Score {
        display: flex;
        align-items: center;
        
        svg {
            width: 14px;
            height: 14px;
        }
    }

    &-ScoreValue {
        color: $black;
        letter-spacing: 0.2px;
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        margin-left: 3px;

        @include desktop {
            font-size: 14px;
        }
    }
}
