@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

[dir='ltr'] .SearchField {
    --search-clear-background: var(--secondary-base-color);
    --search-field-background: #ffffff;
    --search-field-border-color: #000000;
    --search-field-color: var(--secondary-base-color);
    --search-field-visible-background: #fff;
    --search-input-desktop-border-color: #000;
    --search-field-offset-mobile: var(--main-offset-mobile);

    background: $white;

    @include mobile-and-tablet {
        width: auto;
        display: flex;
        margin: 0 0 0 auto;
    }

    @include tablet {
        max-width: 100%;
        grid-column: unset;
    }

    @include above-narrow-desktop {
        max-width: 441px;
        margin: 0;
        margin-left: auto;
        margin-right: 35px;
    }

    &-Input {
        background: $grey;
        border-radius: 100px;
        padding: 13px 16px;
        letter-spacing: 0.2px;
        font-weight: 275;
        font-size: 12px;
        line-height: 14px;
        min-height: 44px;
        color: #3E3D3D;
        border-color: transparent;

        @include mobile-and-tablet {
            display: none;
        }

        &::placeholder {
            color: var(--main-icons);
        }

        @include desktop {
            &:hover {
                border-color: $black;
            }
        }

        &_isActive,
        &_isOnMenu {
            background: $white;
            border-color: $black;

            @include mobile-and-tablet {
                display: block;
                width: 100%;
            }
        }
    }

    &-CloseIcon,
    &-SearchIcon {
        right: 0;
        top: 0;
        background: $black;
        height: 44px;
        width: 44px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        @include mobile-and-tablet {
            position: static;
        }

        @include mobile {
            height: 34px;
            width: 34px;
        }

        @include tablet {
            width: 44px;
            height: 44px;
        }
    }

    &_isActive,
    &_isOnMenu {
        @include mobile-and-tablet {
            left: 0;
            box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
            padding: 24px var(--search-field-offset-mobile) 14px;
            width: 100%;
            position: absolute;
            top: var(--header-nav-height);
            background-color: $white;
            z-index: 1;
        }

        >div {
            @include mobile-and-tablet {
                width: 100%;
            }
        }

        .SearchField-Input {
            @include mobile {
                height: 48px;
            }
        }

        .SearchField-SearchIcon,
        .SearchField-CloseIcon {
            @include mobile-and-tablet {
                position: absolute;
                right: 0;
                top: 0;
            }

            @include mobile {
                height: 48px;
                width: 48px;
            }
        }
    }

    &_isOnMenu {
        @include tablet {
            max-width: 100%;
            width: 100%;
            margin: 0;
            grid-column: unset;
        }

        &,
        &_isActive {
            @include mobile-and-tablet {
                position: absolute;
                top: 0;
                margin-left: 0;
                left: 0;
            }

            @include tablet {
                padding: 8px var(--search-field-offset-mobile);
            }

            @include mobile {
                padding: 16px var(--search-field-offset-mobile);
            }

            .SearchField-SearchIcon,
            .SearchField-CloseIcon {
                @include mobile-and-tablet {
                    position: absolute;
                    right: 0;
                    top: 0;
                    background: $black;
                }
            }

            .SearchField-Input {
                border: 1px solid $black;
                background-color: $white;
            }

            .SearchField-CloseIcon {
                @include mobile {
                    background-color: $white;
                    height: 46px;
                    width: 46px;
                    right: 1px;
                    top: 1px;
                    cursor: pointer;
                }

                svg {
                    @include mobile {
                        width: 12px;
                        height: 12px;
                    }

                    path {
                        @include mobile {
                            fill: $black;
                        }
                    }
                }
            }
        }
    }
}
