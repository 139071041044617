@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.ProductWishlistButton {
    --wishlist-heart-size: 52px;
    --loader-scale: 0.4;

    .Loader {
        border-radius: 100%;
        overflow: hidden;
    }

    svg {
        path:first-of-type {
            opacity: 0;
        }
    }
}
