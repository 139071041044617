@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --top-bar-height: 0px;
}

.TopBarWidget {
    background: $black;

    &-Container {
        height: 32px;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        margin: 0 auto;
        width: 100%;
        max-width: var(--content-wrapper-width);
    }

    .slick-track {
        display: flex;
        min-width: 100%;
    }

    .slick-slide {
        height: inherit;

        & > div, & > div > div, & > div > div > div {
            height: 100%;
        }
    }

    &-Main {
        @include slickSlideBetween(10px);

        overflow: hidden;

        &Slide {
            display: grid;
            align-items: center;
            justify-content: center;
            grid-template-columns: repeat(1, minmax(0, 1fr));

            & > * {
                display: grid;
                align-items: center;
                justify-content: center;
                grid-template-columns: repeat(1, minmax(0, 20px)) repeat(1, minmax(0, auto)) ;
                grid-gap: 16px;

                img:not(.image-default-style) {
                    justify-self: flex-end;
                    max-height: 18px;
                    max-width: 100%;
                    width: auto;
                    height: auto;
                }
            }
        }
    }

    &-Additional {
        overflow: hidden;

        &, & > * {
            font-family: $font-poppins;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            color: $white;
            margin: 0;
        }
    }

    @at-root .topbar {
        --top-bar-height: 32px;
    }
}
