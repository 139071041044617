@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.InstallPromptIOS {
    background: $white;
    padding: 12px;
    margin: 12px;
    border-radius: 9px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(0, 0, 0, 0.2);

    @include mobile {
        padding: 14px;
        margin: 14px;
    }

    &-Button {
        text-align: left;

        .Logo {
            max-width: 150px;

            .lazy-load-image-background {
                position: static;
            }
        }
    }

    &-Content {
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 0;
    }

    &-Close {
        position: absolute;
        inset-block-start: 12px;
        inset-inline-end: 12px;

        @include mobile {
            inset-block-start: 14px;
            inset-inline-end: 14px;
        }
    }

    &-Plus {
        @include ios-plus;

        display: inline-block;
        bottom: -6px;
        transform: scale(.9);
        transform-origin: 50% 50%;
    }

    &-Share {
        @include ios-share;

        display: inline-block;
        bottom: -6px;
        transform: scale(.8);
        transform-origin: 50% 50%;
    }
}
