@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@mixin expanded-content {
    opacity: 1;
    max-height: 100%;
}

.ExpandableContent {
    &-Heading {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: .5px;
        color: $black;
        display: flex;
        align-items: center;
        word-break: break-word;

        @include mobile {
            font-weight: 500;
            font-size: 16px;
        }
    }

    &-HeadingValueWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-Button {
        cursor: pointer;

        @include mobile {
            padding: 20px;
        }
    }

    &-HeadingContainer {
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-Content {
        @include mobile {
            max-height: 0;
            overflow: hidden;
            opacity: 0;
        }

        &_isContentExpanded {
            @include mobile {
                padding: 0 20px;

                @include expanded-content;
            }
        }

        &_isExpandableOnDesktop {
            max-height: 0;
            overflow: hidden;
            opacity: 0;

            &.ExpandableContent-Content_isContentExpanded {
                @include expanded-content;
            }
        }
    }

    &-HeadingValue {
        font-size: 12px;
        font-weight: 600;
        color: black;
        margin-right: 15px;
    }
}
