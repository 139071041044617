@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --footer-nav-height: 72px;
}

.Footer {
    @include mobile {
        margin-bottom: calc(var(--footer-nav-height));
    }

    &-CmsBlockWrapper {
        .CmsBlock-Wrapper {
            width: 100%;

            @include tablet {
                padding: 20px 0;
            }
        }
    }

    &-Columns {
        @include desktop {
            padding-top: 77px;
            padding-bottom: 45px;
        }

        @include wide-desktop {
            padding-inline: 20px;
        }

        @include mobile {
            padding-top: 0;
            padding-bottom: 0;
        }

        @include tablet {
            padding-inline: 14px;
        }
    }

    &-Content {
        background-color: var(--color-white);
    }

    &-CopyrightAndSocialIcons {
        padding-top: 19px;
        padding-bottom: 19px;

        @include desktop {
            padding-top: 0;
            padding-bottom: 21.5px;
        }

        > .ContentWrapper .CmsBlock-Wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            @include desktop {
                flex-direction: row;
                padding-inline: 0;
            }

            > * {
                @include desktop {
                    flex: 1;
                }
            }
        }
    }

    &-Copyright {
        color: #3e3d3d;
        margin: 16px 0 0 0;
        font-size: 13px;
        padding: 0;

        @include desktop {
            text-align: left;
            font-size: 14px;
            margin: 0;
        }
    }

    &-SocialIcons {
        display: flex;
        column-gap: 16px;
        order: -1;
        margin-left: -14px;

        @include tablet {
            margin-left: 0;
        }

        @include desktop {
            order: unset;
            column-gap: 28px;
        }

        li {
            margin: 0;
        }

        svg {
            width: 48px;
            height: 48px;
            stroke: $white;

            path.filled-icon-path {
                fill: $black;
            }

            path.white-icon-path {
                fill: $white;
            }
        }
    }

    &-Seo {
        background: #e3e3e3;
        padding-block: 30px;

        @include disable-select;

        &-Columns {
            width: 100%;

            @include desktop {
                display: flex;
            }
        }

        &-Column {
            @include desktop {
                width: 50%;

                &_Left {
                    padding-inline-end: 5%;
                    border-inline-end: 2px solid $default-primary-base-color;
                }

                &_Right {
                    padding-inline-start: 5%;
                }
            }

            * {
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                letter-spacing: 0.2px;
            }

            h1, h2, h3, h4, h5, h6, p, ul, ol {
                margin-block-end: 20px;
            }

            strong {
                font-weight: 600;
            }

            a {
                color: #3e3d3d;
            }

            ul, li {
                list-style-type: disc;
            }
        }
    }

    &-PaymentIcons {
        background: #e3e3e3;

        .Footer-Copyright {
            @include mobile {
                display: none;
            }
        }

        .CmsBlock-Wrapper > div {
            @include desktop {
                display: flex;
                align-items: center;
            }
        }
    }

    &-PaymentIconsList {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 10px;
        min-height: 55px;

        @include mobile {
            padding: 10px 0;
        }

        @include desktop {
            min-height: 74px;
            justify-content: flex-start;
            margin-left: auto;
            padding-left: 70px;
        }

        li {
            margin: 0;

            @include desktop {
                margin-right: 5px;
            }
        }

        img {
            max-height: 45px;
        }
    }
}
