@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --availability-color: #8A8A8A;
    --availability-24h-color: #3BBB76;
    --availability-out-of-stock-color: #f26522;
    --availability-info-color: #0f6de1;
}

.AvailabilityInfo {
    min-height: 63px;

    &-Item {
        width: 100%;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.2px;
        margin: 0 0 9px;
        text-align: left;
        text-transform: uppercase;
        color: var(--availability-color);

        svg {
            margin-right: 8px;

            path {
                fill: var(--availability-color);
            }
        }

        &_is24h {
            color: var(--availability-24h-color);

            svg {
                path {
                    fill: var(--availability-24h-color);
                }
            }
        }

        &_isInfo {
            letter-spacing: 0.2px;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: var(--availability-info-color);

            svg {
                margin-right: 9px;
                width: 24px;
                height: 18px;

                path {
                    fill: var(--availability-info-color);
                }
            }
        }

        &_isOutOfStock {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.2px;
            text-transform: uppercase;
            color: $orange;
            z-index: 2;
            margin-top: 11px;

            svg {
                margin-right: 9px;

                path {
                    fill: var(--availability-out-of-stock-color);
                }
            }
        }
    }
}
