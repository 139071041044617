@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --category-grid-images-with-single-image-border: 1px solid rgba(62, 61, 61, 0.1);
}

.CategoriesGridImagesWithSingleImage {
    border: var(--category-grid-images-with-single-image-border);
    border-bottom: 0;
    margin: 20px auto;

    @include mobile {
        border-right: 0;
    }

    @include desktop {
        display: flex;
        border-right: 0;
    }

    .Image {
        padding: 0;
        max-width: 100%;

        @include desktop {
            max-width: 100%;
        }
    }

    &-Heading {
        color: black;
        margin-bottom: 14px;
        font-weight: 600;
    }

    &-Link {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;

        @include mobile {
            min-height: 280px;
        }

        p {
            font-size: 14px;
            font-weight: 600;
            margin-top: 15px;
            text-align: center;
        }

        &_isHeading {
            p {
                font-weight: 300;
            }
        }
    }

    &-Single {
        border-right: var(--category-grid-images-with-single-image-border);
        border-bottom: var(--category-grid-images-with-single-image-border);

        @include desktop {
            width: 25%;
        }

        .Image {
            max-width: 200px;
        }
    }

    &-List {
        display: flex;
        flex-wrap: wrap;

        @include desktop {
            width: 75%;
        }

        .CategoriesGridImagesWithSingleImage-Link {
            @include mobile {
                width: 50%;
                border-right: var(--category-grid-images-with-single-image-border);
                border-bottom: var(--category-grid-images-with-single-image-border);
            }

            @include desktop {
                width: 33.33%;
                border-bottom: var(--category-grid-images-with-single-image-border);
                border-right: var(--category-grid-images-with-single-image-border);
            }

            .Image img {
                object-fit: contain;
                max-height: 200px;
            }
        }
    }
}
