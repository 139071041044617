@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.ImageCarousel {
    display: grid;
    grid-template-columns: 100%;

    &_dots {
        padding-bottom: 25px;
    }

    .top-left {
        align-items: flex-start;
        justify-content: flex-start;
    }

    .top-center {
        align-items: flex-start;
        justify-content: center;
    }

    .top-right {
        align-items: flex-start;
        justify-content: flex-end;
    }

    .middle-left,
    .center-left {
        align-items: center;
        justify-content: flex-start;
    }

    .middle-center,
    .center-center {
        align-items: center;
        justify-content: center;
    }

    .middle-right,
    .center-right {
        align-items: center;
        justify-content: flex-end;
    }

    .bottom-left {
        align-items: flex-end;
        justify-content: flex-start;
    }

    .bottom-center {
        align-items: flex-end;
        justify-content: center;
    }

    .bottom-right {
        align-items: flex-end;
        justify-content: flex-end;
    }

    &-Image {
        display: block;

        &:not(&.below) {
            display: flex;
        }

        img {
            max-width: 100%;
            width: auto;
            height: auto;
        }

        .item-content {
            position: absolute;
            text-align: center;
            color: #333;
            z-index: 1;
            font-size: 14px;
        }

        &.below {
            .item-content {
                position: static;
            }
        }

        .item-description {
            margin-top: 5px;
        }
    }
}
