@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.CheckoutDeclaration {
    &_variant {
        &_shipping {
            margin-top: 40px;
            padding-top: 40px;
            border-top: 1px solid $gray-light;

            @include mobile {
                margin-top: 32px;
                padding-top: 32px;
            }
        }

        &_billing {
            margin-bottom: 40px;
            padding-bottom: 40px;
            border-bottom: 1px solid $gray-light;
        }
    }

    &-Heading {
        h2 {
            margin-top: 0;

            @include mobile {
                font-weight: 500;
            }
        }

        span {
            color: var(--product-labels-top);
        }
    }

    &-DeclarationForm {
        display: flex;
        align-items: center;
        grid-gap: 20px;
        margin-bottom: 24px;

        @include mobile {
            flex-direction: column;
            align-items: flex-start;
        }

        .Field_type_select {
            width: 100%;
            max-width: 277px;

            @include mobile {
                max-width: 100%;
            }
        }

        .FieldSelect-Select {
            width: 100%;
        }

        .Field-ErrorMessages {
            font-size: 12px;
            line-height: 15px;
            font-weight: 400;
        }
    }

    &-Declaration {
        &Label {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
        }
    }

    &-DigitalConsent {
        &Agree {
            color: $default-green-color;
        }

        &Disagree {
            color: $default-primary-base-color;
        }
    }

    &-AgreementForm {
        &Wrapper {
            background: $grey;
            border-radius: 9px;
            padding: 24px;

            @include mobile {
                padding: 16px;
            }
        }

        .FieldGroup {
            padding-left: 0;
            border-left: 0;
        }

        .Field-Wrapper {
            &:first-child {
                margin-top: 0;
            }

            margin-top: 16px;
        }

        .Field_type_checkbox label:first-of-type, .Field_type_radio label:first-of-type {
            display: flex;
            align-items: center;
        }

        .Field-RadioLabel {
            padding: 12px 24px;
            background-color: $white;
            border-radius: 100px;
            border: 1px solid $gray-light;
            overflow: hidden;

            span {
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                letter-spacing: .3px;
            }
        }

        input[type='radio'] + .input-control {
            width: 18px;
            height: 18px;
        }

        .Field-ErrorMessage {
            margin-top: 10px;
        }
    }

    &-Agreement {
        &Content {
            &_info {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                margin-bottom: 24px;

                @include mobile {
                    margin-bottom: 20px;
                }

                span {
                    display: block;
                    margin-top: 18px;
                    padding: 16px;
                    background: $red;
                    color: $white;
                    border-radius: 9px;
                }
            }

            &_disagreement {
                color: $red;
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
                margin-bottom: 0;
            }
        }

        &Additional {
            padding-top: 16px
        }
    }
}
