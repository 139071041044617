@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.BannerWidget {
    display: block;

    > a { 
        display: inline-block;
        width: 100%;
        height: 100%;
    }

    img {
        object-fit: contain;
        object-position: top center;
    }
}
