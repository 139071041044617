@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.HeadingWidget {
    margin-bottom: 5px;

    &,
    a {
        color: $black;
        letter-spacing: 0.2px;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;

        @include desktop {
            font-weight: 500;
            font-size: 21px;
            line-height: 25px;
            letter-spacing: 0.5px;
        }
    }
}
