@mixin desktop {
    @media (min-width: 811px) {
        @content;
    }
}

@mixin above-narrow-desktop {
    @media (min-width: 1024px) {
        @content;
    }
}

@mixin ultra-narrow-desktop {
    @media (min-width: 810px) and (max-width: 1160px) {
        @content;
    }
}

@mixin narrow-desktop {
    @media (min-width: 1024px) and (max-width: 1280px) {
        @content;
    }
}

@mixin wide-desktop {
    @media (min-width: 1280px) {
        @content;
    }
}

@mixin widest-desktop {
    @media (min-width: 1440px) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: 768px) and (max-width: 1023px) and (-webkit-min-device-pixel-ratio: 1) {
        @content;
    }
}

@mixin tablet-portrait {
    @media (min-width: 768px) and (max-width: 1023px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: portrait) {
        @content;
    }
}

@mixin tablet-landscape {
    @media (min-width: 768px) and (max-width: 1023px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
        @content;
    }
}

@mixin tablet-small {
    @media (min-width: 768px) and (max-width: 810px) and (-webkit-min-device-pixel-ratio: 1) {
        @content;
    }
}

@mixin mobile-and-tablet {
    @media (max-width: 1023px) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: 810px) {
        @content;
    }
}

@mixin mobile-small {
    @media (max-width: 480px) {
        @content;
    }
}

@mixin standalone {
    // stylelint-disable-next-line
    @media all and (display-mode: standalone) {
        @content;
    }
}

@mixin hoverable {
    @media (hover: hover) and (pointer: fine) {
        @content;
    }
}
