@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.NewOpeningsWidget {
    &-Gallery {
        li {
            margin: 0;
        }
    }

    &-ContentDetail {
        font-weight: 400;

        &_type_Localization {
            svg {
                margin-left: 2px;
            }
        }
    }
}

.CmsPage {
    .NewOpeningsWidget {
        &-Title {
            @include mobile {
                font-size: 16px;
            }
        }
    }
}
