@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --product-slider-tab-color: #3e3d3d;
    --product-slider-tab-bg: #{white};
    --product-slider-tab-color-active: #{white};
    --product-slider-tab-border-active: #2680C3;
    --product-slider-tab-bg-active: #3e3d3d;
    --product-slider-tab-nav-border: #f1f2f3;
}

.CmsPage .CmsElementsTabs-Title {
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    @include mobile {
        text-align: left;
        margin: 24px 0 19px 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.6px;
    }

    @include desktop {
        margin: 0;
    }
}

.CmsElementsTabs {
    z-index: 0;

    &:hover {
        z-index: 1;
    }

    &-CmsElementsTabs, &-Tabs {
        display: flex;
        align-items: center;
        width: auto;
        margin-bottom: 25px;

        @include mobile {
            flex-wrap: nowrap;
            overflow-x: auto;
            height: 52px;
            margin-bottom: 18px;
            width: 100%;
            -webkit-overflow-scrolling: touch;
            column-gap: 4px;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        @include desktop {
            margin-bottom: 0;
        }
    }

    &-Placeholder {
        @include placeholder(500px);
    }

    &-Tab {
        border-radius: 7px;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.2px;
        background: var(--product-slider-tab-bg);
        color: var(--product-slider-tab-color);
        cursor: pointer;
        padding: 14px 24px 14px 24px;
        border: 1px solid var(--product-slider-tab-color);

        @include desktop {
            font-weight: 300;
            font-size: 17px;
            color: $black;
            background-color: $white;
            letter-spacing: 0.8px;
            border-radius: 0;
            border: 0;
            border-bottom: 4px solid transparent;
            line-height: 20px;
        }

        &_isActive {
            background: var(--product-slider-tab-bg-active);
            color: var(--product-slider-tab-color-active);
            line-height: 17px;

            @include desktop {
                background-color: $white;
                color: $black;
                font-weight: 500;
                border-bottom: 4px solid var(--product-slider-tab-border-active);
            }
        }
    }

    &-Nav {
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--product-slider-tab-nav-border);

        @include mobile {
            display: block;
        }

        @include desktop {
            margin-bottom: 50px;
        }
    }

    &-ContentRow {
        margin-bottom: 12px;

        &:hover {
            z-index: 3;
        }
    }

    &-Content {
        &:hover {
            z-index: 3;
        }
    }

    h3.CmsElementsTabs-ContentElement {
        display: block;
    }

    &-SingleRow {
        margin-bottom: 50px;
    }

    &-TextWrapper {
        margin-bottom: 15px;

        > strong {
            font-size: 14px;
        }

        > .CmsElementsTabs-ContentElement {
            display: inline;
            margin-bottom: 0;
        }
    }
}
