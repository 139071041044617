@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

#ceneo-widget {
    top: 180px !important;
    bottom: auto !important;

    @media screen and (max-height: 555px) {
        display: none !important;
    }

    @include tablet {
        top: 70px !important;
        bottom: auto !important;
    }

    @include mobile {
        display: none !important;
    }
}
