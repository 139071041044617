@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.ProductAuthors {
    min-height: 17px;
    font-weight: 300;
    color: $black;

    a {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.2px;
        color: $black;
        font-weight: 300;

        &:hover {
            color: var(--link-hover);
        }
    }

    &_isShort {
        max-height: 17px;

        @include show-text-in-x-lines(1);
    }
}
