@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --product-list-magezon-widget-border-color: #DFDFDF;
}

.CmsPage-Content .ProductListMagezonWidget h2 {
    text-align: center;

    @include mobile {
        text-align: left;
    }
}

.ProductListMagezonWidget {
    .ProductPublisherOrProducer {
        text-align: left;
        margin-top: 0;
        margin-bottom: 10px;
    }

    &_isLightVariant {
        display: block;

        .ProductListMagezonWidget-Name {
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            letter-spacing: 0.2px;
            margin-top: 12px;
            min-height: 30px;
        }

        .ProductListMagezonWidget-Products {
            display: grid;
            grid-template-columns: 1fr 1fr;
            border: 1px solid var(--product-list-magezon-widget-border-color);
            border-radius: 9.5px;
            row-gap: 0;
            min-height: 581px;
        }

        .ProductListMagezonWidget-PictureWrapper {
            flex: 1 0 90px;
            max-width: 90px;
            height: 120px;
            margin: 0 auto;
        }
    }

    &-Name {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.2px;
        color: $black;
        display: block;
        width: 100%;
        margin-bottom: 4px;
        min-height: 36px;

        @include show-text-in-x-lines();
    }

    .ProductPrice {
        margin: auto 0 13px;
    }

    &-Labels {
        display: flex;
        grid-gap: 6px;
        margin-bottom: auto;
    }

    &-Placeholder {
        @include placeholder(660px);
    }

    &-PictureWrapper {
        display: block;
        flex: 1 0 90px;
        max-width: 90px;
        height: 120px;
        margin-right: 13px;
    }

    &-Products {
        display: flex;
        row-gap: 12px;
        flex-wrap: wrap;

        @include mobile {
            flex-wrap: nowrap;
            overflow-x: auto;
            height: 145px;
            margin-bottom: 20px;
            width: 100%;
            -webkit-overflow-scrolling: touch;
            column-gap: 16px;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        @include desktop {
            flex-direction: column;
        }
    }

    &-Product {
        border: 1px solid var(--main-text-and-icons-bg);
        border-radius: 9.5px;
        padding: 12px;
        width: 100%;
        display: flex;

        .ProductPrice-PriceValue {
            font-size: 16px;
        }

        .ProductPrice-HighPrice {
            font-size: 12px;
        }

        .ProductCard-Labels {
            justify-content: flex-start !important;
        }

        .ProductPrice del.ProductPrice-HighPrice {
            margin: 0 4px;
        }

        > div {
            display: flex;
            flex-direction: column;
        }

        &:not(&_isLightVariant) {
            min-width: 295px;
        }

        &_isLightVariant {
            display: block;
            border: 0;
            margin: 0;
            border-radius: 0;
            border-top: 1px solid var(--product-list-magezon-widget-border-color);
            padding: 16px;

            &:nth-child(odd) {
                border-right: 1px solid var(--product-list-magezon-widget-border-color);
            }

            &:nth-child(1),
            &:nth-child(2) {
                border-top: 0;
            }
        }
    }
}
