@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.OmnibusPriceInfo {
    margin: 0;
    text-align: center;

    &-Content {
        font-weight: 400;
        font-size: 11px;
        line-height: 14px;
        color: $grey3;
        letter-spacing: 0.2px;
        margin: 0;
    }
}
