@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --product-attribute-border-color: #e8e8e8;
}

.CategorySingleFilterOverlay {
    top: calc(var(--header-nav-height) - 35px);
    width: 100%;
    background: var(--color-white);
    bottom: 0;
    padding: 20px 0;

    @include mobile {
        --navigation-tabs-height: calc(83px + env(safe-area-inset-bottom));
    }

    .ProductAttributeValue {
        display: block;
        padding: 15px 20px 10px;
        border-bottom: 1px solid var(--product-attribute-border-color);
    }

    .ProductConfigurableAttributes-DropDownList {
        margin-block: 0;
    }

    &-FiltersWrap {
        z-index: 0;
    }

    &-SeeResults {
        @include mobile {
            width: 100%;
            padding: 16px 20px;
            position: fixed;
            bottom: 0;
            background-color: var(--color-white);
            border-top: 1px solid var(--primary-divider-color);
        }
    }

    &_isVisible {
        z-index: 101;
    }
}
