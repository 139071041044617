@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --button-widget-bg: var(--product-labels-top);
    --button-widget-color: #{$white};
    --button-widget-hover-bg: var(--product-labels-top);
    --button-widget-hover-color: #{$white};
}

.ButtonWidget {
    &_isOverlay {
        position: absolute;
        inset: 0;
        height: 100%;
        width: 100%;
    }

    &:not(&_isOverlay) {
        --button-background: var(--button-widget-bg);
        --button-border: var(--button-widget-bg);
        --button-color: var(--button-widget-color);
        --button-height: initial;
        --button-padding: 8px;
        --button-padding-block: 8px;
        --button-hover-background: var(--button-widget-hover-bg);
        --button-hover-border: var(--button-widget-hover-bg);
        --button-hover-color: var(--button-widget-hover-color);
        --button-hover-height: initial;
        --button-hover-padding: 8px;
        --button-hover-padding-block: 8px;

        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.3px;
        margin-left: auto;
        height: var(--small-button-height);

        &:not([disabled]) {
            &:hover {
                @include desktop {
                    height: var(--small-button-height);
                }
            }
        }

        span {
            margin-right: 6px;
        }

        svg {
            path {
                fill: var(--button-widget-color);
            }
        }
    }
}
