@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --price-color: #{$black};
    --price-with-discount-color: #{$black};
    --price-with-omnibus-color: #{$default-primary-base-color};
    --price-high-color: #{$black};
}

[dir='ltr'] .ProductPrice {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include mobile {
        justify-content: center;
    }

    @include desktop {
        min-height: unset;
    }

    &-PriceValue {
        letter-spacing: 0.7px;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin: 0;
    }

    &-HighPrice {
        font-size: 11px;
        font-weight: 400;
        line-height: 14px;
        color: var(--price-high-color);
        opacity: 1;
    }


    &-UOMValue {
        font-size: 11px;
        font-weight: 400;
        line-height: 14px;
        color: var(--price-high-color);
        opacity: 1;
        margin: 8px 0 0;
        text-align: left;
        max-width: 200px;
    }

    &_hasDiscount {
        .ProductPrice-PriceValue {
            color: var(--price-with-discount-color);
        }
    }

    &_hasOmnibus {
        .ProductPrice-PriceValue {
            color: var(--price-with-omnibus-color);
        }
    }

    &-OldPriceWrapper {
        display: inline;
    }

    &-DiscountAmount {
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: var(--price-color);
        margin-top: 8px;

        @include mobile {
            width: 100%;
            text-align: center;
        }
    }
}
