@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.Checkout {
    padding-block-end: 0;
    overflow: inherit;

    @include mobile {
        --footer-total-height: 108px;

        margin-bottom: calc(var(--navigation-tabs-height) + var(--footer-total-height));
    }

    @include desktop {
        margin-block-start: 0;
    }

    @include mobile {
        padding-block-start: 0;
    }

    &::before {
        display: none;
    }

    &_step {
        &_SHIPPING_STEP,
        &_BILLING_STEP {
            .Checkout-Wrapper {
                @include desktop {
                    margin-top: 48px;
                    grid-template-columns: minmax(calc(100% - 420px), 1fr) minmax(400px, 1fr);
                    grid-gap: 20px;
                }

                @include tablet {
                    grid-template-columns: minmax(calc(100% - 390px), 1fr) minmax(350px, 1fr);
                }

                @include mobile {
                    margin-top: 32px;
                }
            }
        }

        &_DETAILS_STEP {
            .Checkout-Wrapper {
                display: grid;
                grid-template-columns: 1fr;
            }
        }

        &_SHIPPING_STEP {
            .Checkout {
                &-Heading {
                    margin: 0;
                    padding: 0 0 24px;
                }

                &-Step {
                    @include mobile {
                        margin-bottom: 32px;
                    }
                }
            }

            .CheckoutShipping {
                &-Form {
                    .Checkout-Heading {
                        border-top: 1px solid $gray-light;
                        margin: 40px 0 0;
                        padding: 40px 0 24px;

                        @include mobile {
                            margin: 32px 0 0;
                            padding: 32px 0 24px;
                        }
                    }
                }
            }
        }

        &_BILLING_STEP {
            .Checkout {
                &-Heading {
                    margin: 0;
                    padding: 0 0 24px;

                    @include mobile {
                        padding: 32px 0 18px;
                    }
                }
            }
        }
    }

    &-Wrapper {
        @include desktop {
            padding: 0 30px;
        }

        @include mobile {
            padding: 0 20px;
        }
    }

    &-DeliveryCountry {
        display: grid;
        grid-template-columns: 100px auto;
        align-items: center;
        grid-gap: 20px;

        @include mobile-and-tablet {
            margin-bottom: 24px;
        }

        &Label {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: .3px;
            margin-bottom: 0;
        }

        .FieldSelect {
            max-width: 277px;
            width: 100%;

            .ChevronIcon {
                width: 16px;
                height: 16px;
            }
        }

        .FieldSelect-Select {
            width: 100%;
        }
    }

    &-StickyButtonWrapper {
        &.CheckoutOrderSummary {
            &-ButtonWrapper {
                display: none;

                @include mobile {
                    display: block;
                }
            }
        }
    }

    .CheckoutOrderSummary {
        @include desktop {
            position: sticky;
            top: 100px;
        }
    }
}
