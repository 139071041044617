@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.CookiePopup {
    position: fixed;
    display: flex;
    align-items: flex-end;
    inset-block-end: 0;
    inset-inline: 0;
    z-index: 999;

    &:not(&_isCustomize) {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    }

    &Button {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        width: 48px;
        border-radius: 50%;
        background-color: $white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        inset-block-end: calc(var(--footer-nav-height, 0px) + 16px);
        inset-inline-start: 16px;
        z-index: 999;

        @include desktop {
            inset-block-end: 16px;
        }
    }

    &_isCustomize {
        inset: 0;
        justify-content: center;
        align-items: center;

        .CookiePopup {
            &-Content {
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
                max-width: var(--popup-max-width);
                border-radius: 9px;

                @include desktop {
                    max-width: 900px;
                }

                @include mobile {
                    height: 100%;
                }
            }

            &-Groups {
                @include desktop {
                    max-height: 335px;
                }
            }

            &-Footer {
                display: grid;
                grid-template-columns: 1fr;

                .Button_back {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 40px;
                    padding: 0;

                    svg {
                        display: none;
                        left: -1px;
                    }

                    @include desktop {
                        width: 40px;

                        span {
                            display: none;
                        }

                        svg {
                            display: block;
                        }
                    }
                }

                @include desktop {
                    grid-template-columns: 40px 1fr 1fr 1fr;
                }
            }
        }
    }

    &-Wrapper {
        max-width: var(--content-wrapper-width);
        height: 100%;
        margin-inline: auto;
        padding-inline: 16px;

        @include desktop {
            padding-inline: 32px;
        }
    }

    &-Content {
        width: 100%;
        padding-block: 16px;
        background-color: $white;

        @include desktop {
            padding-block: 32px;
        }
    }

    &-Form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    &-Footer {
        display: block;
        padding-top: 16px;

        @include mobile {
            text-align: center;
        }

        @include desktop {
            display: flex;
            align-items: center;
            padding-top: 32px;
        }

        a {
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.2px;
            color: $violet;
        }
    }


    &-Groups, &-Text {
        overflow-y: auto;

        @include scrollbar();
    }

    &-Text {
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.2px;

        @include mobile {
            max-height: 305px;
        }
    }

    &-Groups {
        margin-inline-end: -8px;
    }

    &-Group {
        padding-inline-end: 8px;

        &:not(:last-child) {
            border-bottom: 1px solid $gray-light;
            margin-block-end: 16px;
            padding-block-end: 16px;
        }

        .Field-Wrapper {
            margin-block-end: 12px;

            & .Field-SwitcherLabel {
                justify-content: space-between;

                [type='checkbox']:checked + .switcher-control {
                    background-color: $default-green-color;
                }

                .switcher-control {
                    margin-inline-start: auto;
                }
            }
        }

        &Label {
            display: flex;
            align-items: center;
            font-weight: 500;

            &Icon {
                margin-inline-end: 8px;
            }

            &Length {
                margin-inline-start: 8px;
                font-weight: 400;
                color: $grey3;
            }
        }

        &Text {
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.2px;
            margin-block: 0;
        }

        &List {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 8px;
        }

        &Item {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 16px;
            padding: 16px;
            border-radius: 9px;
            border: 1px solid $gray-light;

            &Detail {
                display: grid;
                grid-template-columns: 1fr;
                grid-gap: 8px;
                align-items: center;
                border-top: 1px solid $gray-light;
                padding-top: 16px;

                @include desktop {
                    grid-template-columns: 1fr 1fr;
                }
            }
        }
    }

    .CookieShowMore {
        margin-top: 16px;

        &-Button {
            display: grid;
            grid-template-columns: 16px 1fr;
            grid-gap: 8px;
            align-items: center;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.2px;
            margin-block: 0;
        }

        &-Content {
            margin-top: 16px;
        }
    }

    &-Button {
        --button-secondary-background: #{$default-green-color};
        --button-secondary-border: #{$default-green-color};
        --button-secondary-color: #{$white};
        --button-secondary-hover-background: #{$default-green-dark-color};
        --button-secondary-hover-border: #{$default-green-dark-color};

        &[href] {
            color: var(--button-secondary-background);
            font-size: inherit;
        }

        @include mobile {
            display: block;

            &:not(:last-child) {
                &.Button_variant_hollow {
                    margin-block-end: 0;
                }

                margin-block-end: 8px;
            }

            &.CookiePopup-Button_isPrivacyPolicy {
                margin-block-start: 8px;
            }
        }

        @include desktop {
            &:not(:first-child) {
                margin-inline-start: 16px;

                &.CookiePopup-Button_isPrivacyPolicy {
                    margin-inline-start: auto;
                }
            }

            &_isPrivacyPolicy {
                margin-inline-start: auto;
            }
        }
    }

    .ShowMoreLessContent-Wrapper .ShowMoreLessContent-Button::after {
        background: none;
    }
}
