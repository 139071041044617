@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.WishListPriceAlert {
    &-Popup {
        --popup-max-height: none;
        --popup-max-width: 925px;

        inset-inline-start: 0;
        inset-block-start: 0;
        width: 100%;
        height: 100%;
        z-index: 802;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding-block: 24px;
        padding-inline: 24px;
        overflow-y: auto;

        .NotificationList {
            max-width: 100%;
        }

        .Popup-Content {
            display: flex;
            flex-direction: column;
            padding-block: 32px 0;
            padding-inline: 0;
            width: 100%;
            overflow-y: initial;

            &::after {
                padding-block-end: 0;
            }

            @include mobile {
                max-height: var(--popup-max-height);
            }

            @include desktop {
                padding-block-start: 52px;
            }
        }

        .Popup-CloseBtn {
            @include mobile {
                height: 18px;
                width: 18px;
                inset-block-start: 18px;
                inset-inline-end: 18px;

                svg {
                    height: 18px;
                    width: 18px;
                }
            }
        }
    }

    &-Header {
        padding-block-end: 14px;
        padding-inline: 16px;
        text-align: center;
        border-bottom: 1px solid #e8e8e8;

        @include desktop {
            padding-block-end: 16px;
        }
    }

    &-Name {
        max-width: 509px;
        margin-inline: auto;
        margin-block: 0 8px;

        @include mobile {
            font-weight: 500;
        }

        @include desktop {
            margin-block-end: 16px;
        }
    }

    &-ProductList {
        display: grid;
        grid-template-columns: auto;
        grid-gap: 42px 50px;
        padding-block-start: 16px;
        padding-inline: 6px;

        @include desktop {
            padding-inline: 69px;
            padding-block: 32px;
        }

        @include tablet {
            padding-inline: 26px;
            padding-block: 32px;
        }

        li.ProductCard {
            border-inline-end-color: transparent;
        }

        @include desktop {
            grid-template-columns: repeat(3, 1fr);
        }

        @include tablet {
            grid-template-columns: repeat(2, 1fr);
        }

        @include ultra-narrow-desktop {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &-ActionContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-block: 0 32px;
        padding-inline: 16px;

        @include desktop {
            padding-block: 65px;
        }

        @include tablet {
            padding-block: 0 32px;
            padding-inline: 32px;
        }

        .Button {
            width: 100%;
            text-transform: none;

            @include desktop {
                width: 330px;
            }

            @include tablet {
                width: 100%;
            }
        }
    }
}
