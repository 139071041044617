@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.MapWidget {
    &-Wrapper {
        overflow: hidden;
        border-radius: 9px;
        height: 366px;
        width: 90%;
        margin-left: auto;
        margin-top: 80px;

        @include mobile {
            margin: 0;
            width: 100%;
        }
    }
}
