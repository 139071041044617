@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --mobile-button-width: 95%;
    --mobile-button-height: 52px;
}

.CmsStaticSidebarMenu {
    &-CmsStaticSidebarMenuButtonWrapper {
        position: relative;
        text-align: center;
        margin: 30px auto;
        width: var(--mobile-button-width);
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
    }

    &-CmsStaticSidebarMenuButton {
        font-size: 14px;
        letter-spacing: .2px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        column-gap: 10px;
        width: 100%;
        margin: auto;
        height: var(--mobile-button-height);
        border: 0.5px solid $black;
        border-radius: 9px;
        background: $white;
        z-index: 2;

        @include default-transition();
        
        &:hover {
            filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.15));
        }

        > div {
            display: flex;
            align-items: center;
            column-gap: 15px;
        }

        &_isExpandedOnMobile {
            background-color: $black;
            border-bottom-left-radius: 9px;
            border-bottom-right-radius: 9px;
            border-color: $black;

            > div {
                color: $white;
            }

            &:hover {
                filter: none;
            }

            > svg {
                transform: rotate(180deg);

                @include default-transition();
            }
        }
    }
    
    &-CmsStaticSidebarButtonTitle {
        overflow: hidden;
        white-space: nowrap;
        max-width: 100%;
        text-overflow: ellipsis;

        &_isExpandedOnMobile {
            color: $white;
        }
    }

    &-CmsStaticSidebarMenuButtonContent {
        max-width: 90%;
    }

    &-CmsStaticSidebarMenuElementsWrapper {
        position: absolute;
        top: calc(var(--mobile-button-height) - 50%);
        max-height: 0;
        width: 100%;
        overflow: hidden;
        background-color: $white;
        border: 1px solid transparent;
        z-index: 1;

        @include default-transition();

        &_isExpandedOnMobile {
            max-height: 100vh;
            top: var(--mobile-button-height);
            overflow: visible;
            border-bottom-left-radius: 9px;
            border-bottom-right-radius: 9px;
            border-color: var(--main-text-and-icons-bg);
            border-top: 0;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);

            @include default-transition();
        }
    }

    &-CmsStaticSidebarMenuElement {
        display: flex;
        align-items: center;
        column-gap: 15px;
        text-align: center;
        width: 100%;
        padding: 0 15px;
        height: var(--mobile-button-height);
        opacity: 0;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        border-bottom: 1px solid transparent;
        color: $black;

        @include default-transition();

        @include mobile {
            text-align: left;
        }

        &_isExpandedOnMobile {
            opacity: 1;

            &:not(:last-of-type) {
                border-color: var(--main-text-and-icons-bg);
            }

            &:last-of-type {
                border-bottom-left-radius: 9px;
                border-bottom-right-radius: 9px;
            }
        }

        &:hover {
            background-color: $grey;
        }
    }
}
