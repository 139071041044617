@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --product-cart-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.1), 0 6px 16px rgba(0, 0, 0, 0.1), 0 9px 28px 16px rgba(0, 0, 0, 0.1);
    --product-cart-border-radius: 9.5px;
    --product-cart-border-color: #e8e8e8;
    --product-cart-additional-info-color: #8a8a8a;
    --product-cart-shipping-price-color: #3bbb76;
    --white: #fff;
}

#edrone-recommendation {
    margin: 0 auto;
    max-width: var(--content-wrapper-width);
    padding-inline: 20px;
    width: 100%;
    overflow-x: hidden;

    &:not(&:empty) {
        padding-bottom: 60px;
    }

    @include mobile {
        margin: 20px auto;
        padding-bottom: 0;
    }
}

[dir='ltr'] .ProductCard {
    height: auto;
    border: 1px solid transparent;
    border-right: 1px solid var(--product-cart-border-color);

    &:hover {
        @include desktop {
            border: 1px solid var(--product-cart-border-color);

            &,
            .ProductCard-FigureReview {
                border-radius: var(--product-cart-border-radius)
            }

            .ProductCard-Reviews {
                opacity: 1;
            }

            .ProductCard-VisibleOnHover {
                width: calc(100% + 2px);
                margin-inline: -1px;
                border-radius: 0 0 var(--product-cart-border-radius) var(--product-cart-border-radius);
                box-shadow: var(--product-cart-box-shadow);
                border: 1px solid var(--product-cart-border-color);

                &::before {
                    content: '';
                    display: block;
                    inset-inline: 1px;
                    height: 8px;
                    background: $white;
                    position: absolute;
                    top: -8px;
                }
            }
        }
    }

    &-OutOfStock {
        justify-content: center;
    }

    &-Picture {
        img {
            height: 100%;
            object-fit: contain;
        }
    }

    &-ExtraInfo {
        margin-top: 16px;
        min-height: 38px;

        &Item {
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            letter-spacing: 0.2px;
            color: $black;
            margin-top: 8px;

            span {
                color: var(--product-cart-additional-info-color);
            }

            b {
                font-weight: 500;
            }
        }

        + .Field-Wrapper:not(:first-of-type) {
            margin-top: 0;

            input {
                border: 1px solid #e8e8e8;

                &::placeholder {
                    font-size: 11px;
                    line-height: 14px;
                    letter-spacing: 0.2px;
                    color: #8a8a8a;
                }
            }
        }
    }

    .AvailabilityInfo {
        display: flex;
        justify-content: center;
        align-items: center;

        &-Item {
            text-align: center;
            margin: 12px 0 3px;

            @include desktop {
                margin: 8px 0 3px;
            }
        }
    }

    &-Labels {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 6px;
        height: 22px;
        align-items: center;
        overflow: hidden;

        @include mobile {
            gap: 4px;
        }
    }

    &-FigureReview {
        padding-top: 15px;
        padding-bottom: 0;
    }

    &-Figure {
        padding-top: 25px;

        @include mobile {
            display: flex;
            justify-content: center;
        }

        > .ProductLabel-Wrapper {
            position: absolute;
            top: 0;

            @include mobile {
                left: 0;
            }

            .ProductLabel_type_top_with_number {
                @include mobile {
                    margin-right: 5px;
                }
            }
        }
    }

    &_layout_grid {
        @include mobile {
            border-bottom: 1px solid transparent;
            padding-bottom: 10px;
            margin-bottom: 15px;
        }

        @include mobile-small {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    &-Content {
        padding-bottom: 0;

        @include desktop {
            padding-left: 10px;
            padding-right: 10px;
        }

        .ProductPrice-PriceValue {
            font-weight: 600;
            font-size: 18px;

            @include mobile {
                font-size: 14px;
            }
        }

        del.ProductPrice-HighPrice {
            @include mobile {
                font-size: 12px;
            }
        }

        .ProductAuthors {
            text-align: center;
        }
    }

    &-Link {
        border-radius: var(--product-cart-border-radius);
    }

    &-AddToCart {
        margin: 16px auto;

        &.AddToCart {
            padding: 0;
            line-height: 20px;
        }

        @include tablet {
            min-width: inherit;
        }

        @include mobile {
            --button-padding: 0;
            --button-hover-padding: 0;
            --outline-button-padding: 0;
            --outline-button-hover-padding: 0;
            --hollow-button-padding: 0;
            --hollow-button-hover-padding: 0;
            --reveal-button-padding: 0;
            --reveal-button-hover-padding: 0;

            margin-block: 12px 0;
            min-width: inherit;

            @include mobile-small {
                svg {
                    margin-left: 0;
                    left: 0;
                }
            }
        }
    }

    .ProductCard-TopLabelsWrapper {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }

    .ProductWishlistButton {
        --wishlist-heart-size: 32px;

        position: absolute;
        top: -8px;
        right: -12px;

        @include mobile {
            right: 10px
        }

        &-Button.Button {
            width: var(--wishlist-heart-size);

            > svg {
                height: var(--wishlist-heart-size);
                width: var(--wishlist-heart-size);
            }

            &:not([disabled]) {
                &:hover {
                    height: var(--wishlist-heart-size);
                    padding: 0;
                    background-color: var(--button-hover-background);

                    > svg {
                        path {
                            fill: var(--white);
                        }
                    }
                }
            }
        }
    }

    .OmnibusPriceInfo {
        display: flex;
        align-items: center;
        min-height: 22px;
        max-height: 22px;
        max-width: 200px;
        margin-inline: auto;
    }

    &-Footer {
        padding-top: 0;
        display: block;

        > * {
            margin-inline-end: 0;
        }
    }

    &-Reviews {
        position: absolute;
        top: 0;
        right: 23px;
        width: auto;
        opacity: 0;
    }

    &-Name {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.5px;
        color: $black;
        padding: 0;
        margin: 0 auto 10px;
        min-height: 34px;

        @include show-text-in-x-lines();

        a {
            color: $black;
        }
    }

    &-Price {
        text-align: center;
        margin: 10px auto;
        justify-content: center;

        @include mobile-small {
            flex-wrap: wrap;
        }

        &.ProductPrice_hasDiscount {
            .ProductPrice-Price {
                @include mobile-small {
                    order: 3;
                }
            }
        }
    }

    &-PriceWrapper {
        min-height: 40px;
    }

    &_layout_list {
        width: 100%;
        border-color: transparent;
        min-height: 430px;

        &.ProductCard_isProductOtherEdition {
            min-height: 490px;
        }

        &:hover {
            border-color: transparent;
            border-bottom-color: transparent;
            border-radius: 0;
            box-shadow: none;
        }

        .ProductCard-Link {
            width: 100%;
        }

        .ProductCard-FigureReview {
            padding-top: 0px;
        }

        .ProductCard-Figure {
            padding-top: 10px;
        }

        .ProductCard-Picture {
            img {
                max-height: 330px;
                height: 100%;
            }
        }

        .ProductCard-Content_layout_list {
            width: 100%;
            max-width: 100%;
            align-items: flex-start;
            border-radius: var(--product-cart-border-radius);
        }

        .ProductCard-MainInfo,
        .ProductCard-MainInfoReviews {
            display: flex;
            align-items: center;

            &_withLabels {
                margin-bottom: 50px;
            }
        }

        .ProductCard-MainInfo {
            column-gap: 15px;
            width: 100%;
            justify-content: space-between;
        }

        .ProductCard-Reviews {
            position: relative;
            opacity: 1;
            right: auto;
            top: auto;
            display: flex;
            align-items: center;
        }

        .ProductWishlistButton {
            position: relative;
            top: auto;
            right: auto;
            width: auto;
            height: auto;
            margin: 0;

            button {
                &:hover {
                    padding: 0;

                    svg {
                        path {
                            &:nth-child(2) {
                                fill: var(--white);
                            }
                        }
                    }
                }
            }
        }

        .ProductCompareButton {
            display: none;
        }

        .ProductCard-Labels {
            justify-content: flex-start;
            margin-bottom: 12px;
        }

        .ProductCard-Name {
            font-size: 21px;
            font-weight: 500;
            line-height: 120%;
            text-align: left;

            @include show-text-in-x-lines(3);
        }

        .ProductCard-AuthorWrapper {
            display: flex;
            align-items: flex-start;
            grid-column-gap: 5px;
            column-gap: 5px;
            font-size: 14px;
            font-weight: 400;
            line-height: 1;
            letter-spacing: 0.3px;

            .ProductCard-Author {
                font-size: 14px;
                font-weight: 400;
                color: var(--product-labels-top);
            }
        }

        .ProductCard-PriceWrapper {
            margin-bottom: 5px;
            font-size: 18px;

            .OmnibusPriceInfo {
                flex: 1 1 100%;
                margin-inline: 0;
                max-width: none;
                text-align: left;
                margin-top: 4px;
            }
        }

        .ProductCard-Price {
            justify-content: flex-start;
            margin-bottom: 0;
        }

        .ProductCard-ExtraInfoItem,
        .ProductCard-OmnibusPriceInfo {
            text-align: left;
        }

        .ProductCard-ExtraInfoItem {
            display: flex;
            align-items: flex-start;
            column-gap: 5px;

            span {
                font-weight: 600;
                color: $black;
                display: block;
            }
        }

        .ProductCard-ExtraInfoItemPublishersIcons {
            align-items: center;
            margin-top: 12px;
            margin-bottom: -13px;

            .ExtraInfoItemPublishersIcons-Icons {
                column-gap: 10px;

                &,
                > div {
                    display: flex;
                    align-items: center;
                    column-gap: 0px;

                    span {
                        font-size: 7px;
                        font-weight: 300;
                        line-height: 1;
                        margin-bottom: 0;
                        letter-spacing: 0.4px;
                    }
                }
            }
        }

        .ProductCard-ActionWrapper {
            width: 100%;
            column-gap: 25px;
            margin: 25px 0;

            @include ultra-narrow-desktop {
                flex-direction: column;
                align-items: flex-start;
            }

            .AddToCart {
                max-width: 220px;
                padding: 0;
                margin: 0;
            }

            .AvailabilityInfo {
                &-Item {
                    margin: 0;
                }
            }
        }

        .ProductCard-ShippingTimeInfo {
            font-size: 12px;
            font-weight: 300;
            line-height: 1;
            margin-bottom: 0;
            letter-spacing: 0.2px;

            span {
                font-weight: 500;
                color: var(--product-cart-shipping-price-color);
            }
        }
    }

    &-LinkInnerWrapper {
        &_isLightVariant {
            @include desktop {
                min-height: 257px;
            }

            .ProductCard-Name {
                @include show-text-in-x-lines(1);

                min-height: unset;
            }

            .ProductCard-FigureReview {
                padding-top: 0;
                border-radius: var(--product-cart-border-radius);
            }

            .ProductCard-Content {
                padding-left: 0;
                padding-right: 0;
            }
        }

        &_isMenuVariant {
            .ProductCard-Name {
                margin-block-end: 4px;
            }
        }

        &_isPromotedVariant {
            display: grid;
            grid-template-columns: 110px auto;
            align-items: flex-start;
            padding-block: 10px 18px;
            padding-inline: 0;

            .ProductCard-Name,
            .ProductAuthors,
            .ProductPrice {
                text-align: left;
            }

            .ProductCard-Labels,
            .ProductPrice {
                justify-content: flex-start;
            }

            .ProductPrice {
                margin-block-start: 4px;
                margin-block-end: 13px;
            }

            .ProductCard-Name {
                min-height: unset;
                margin-block-end: 4px;
            }

            .ProductCard-FigureReview {
                margin: initial;
                max-width: initial;
                height: 100%;
                width: 100%;
                padding-block: 0;
                padding-inline: 0 10px;
                border-radius: var(--product-cart-border-radius);

                .Image {
                    width: 100%;
                }
            }

            .ProductCard-Figure {
                padding-top: 0;
            }

            .ProductCard-Content {
                padding-top: 0;
                padding-left: 0;
                padding-right: 0;
            }
        }

        &_isWishlistPriceAlert {
            .ProductCard-TopLabelsWrapper {
                position: absolute;
                top: 10px;
                left: 10px;
                z-index: 1;
            }

            .ProductCard-WishlistOldPriceWrapper {
                position: static;

                @include tablet {
                    position: absolute;
                    top: 15px;
                    z-index: 1;
                }
            }

            .ProductLabel-Wrapper {
                position: static;
            }
        }
    }

    .ProductLabel_type_bestsellers_counter,
    .ProductLabel_type_bestsellers_counter_top {
        z-index: 1;
    }

    &.SharedWishlistItem {
        &:not(:hover) {
            border-bottom: 1px solid $gray-light;
        }

        .ProductCard {
            &-Content {
                margin-left: 0;
                padding: 24px 16px;

                @include mobile {
                    padding: 24px 16px 10px;
                }

                @include mobile-small {
                    padding: 10px 16px;
                }
            }

            &-Name {
                font-size: 17px;
                line-height: 20px;
                min-height: auto;
                letter-spacing: .8px;
                margin: 0 0 14px;

                @include mobile {
                    padding-right: 50px;
                }

                @include mobile-small {
                    text-align: center;
                    padding-right: 0;
                }
            }

            &-PriceWrapper {
                min-height: auto;

                @include mobile-small {
                    align-items: center;
                }
            }

            &-Price {
                margin: 0;
            }

            &-OmnibusPriceInfo {
                @include mobile-small {
                    text-align: center;
                }
            }

            &-FigureReview {
                padding: 24px 20px;

                @include mobile {
                    align-self: flex-start;
                }

                @include mobile-small {
                    padding: 24px 20px 10px;
                }
            }

            &-Picture {
                width: 86px;

                @include mobile-small {
                    padding-bottom: 100%;
                }
            }

            &-SharedWishlistComment {
                padding: 24px 16px;

                @include mobile {
                    padding: 10px 16px;
                }

                &Label {
                    display: block;
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 400;
                    margin-bottom: 10px;

                    @include mobile-small {
                        text-align: center;
                    }
                }

                p {
                    font-size: 14px;
                    line-height: 19px;
                    font-weight: 400;
                    color: $grey3;
                    letter-spacing: .3px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    @include mobile {
                        font-size: 12px;
                        line-height: 16px;
                    }

                    @include mobile-small {
                        text-align: center;
                    }
                }
            }

            &-ActionWrapper {
                display: flex;
                align-items: center;
                grid-gap: 30px;
                padding-left: 16px;

                @include tablet {
                    flex-direction: column;
                    align-items: flex-end;
                    grid-gap: 10px;
                    padding-right: 10px;
                }

                @include mobile {
                    position: static;
                    margin: 10px 0 24px;
                }

                @include mobile-small {
                    padding: 0 20px;
                }

                .AddToCart {
                    max-width: 189px;

                    @include mobile-small {
                        max-width: 100%;
                    }
                }

                .ProductWishlistButton {
                    @include mobile {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                    }
                }
            }

            &-WishListButton {
                &.Button {
                    &,
                    &:hover:not([disabled]) {
                        width: 45px;
                        height: 45px;
                    }

                    & > svg {
                        width: 45px;
                        height: 45px;
                    }
                }
            }
        }
    }

    &-TopLabelsWrapper {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 10px;
        align-items: center;
    }

    &-WishlistOldPrice {
        display: inline-flex;
        align-items: center;
        grid-gap: 5px;
        background: linear-gradient(123deg, #DD151E 30.49%, #FC6B01 93.08%);
        padding: 3px 9px;
        border-radius: 25px;

        &Wrapper {
            @include above-narrow-desktop {
                position: absolute;
                top: 15px;
                z-index: 1;
            }
        }

        &Info {
            font-size: 11px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: .2px;
            color: $white;
        }

        &TooltipPrice {
            color: var(--main-primary);
        }
    }
}
