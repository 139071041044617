@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --input-height: 40px;
    --radio-image-height: 180px
}

.GiftCardsBuyCardForm {
    &-FormWrapper {
        background: #F5F5F5;

        @include mobile {
            margin-inline: -14px;
            padding-inline: 14px;
            padding-block: 25px;
        }

        @include desktop {
            border-radius: 9px;
            padding: 25px 35px;
            margin-bottom: 40px;
        }

        .FieldGroup {
            &_isValid,
            &_hasError {
                border-inline-start: 0;
                padding-inline: 0;
            }

            & .Field-Wrapper {
                margin-block: 0;
                margin-top: 30px;
            }
        }
    }

    &-Administrator {
        margin-top: 24px;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.2px;
        color: #A8A8A8;

        a {
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.2px;
            color: #3E3E3E;
            text-decoration: underline;
        }
    }

    .Agreements {
        margin-top: 24px;

        &-Item {
            &:not(:first-of-type) {
                margin-top: 24px;
            }
        }
    }

    .Field_type_checkbox {
        .Field-CheckboxLabel {
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.2px;
            color: #A8A8A8;

            a {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.2px;
                color: #3E3E3E;
            }

            span > span {
                color: #E02020;
            }
        }
    }

    &-LabelRequired {
        color: #E02020;
    }

    &-TabTitle {
        text-align: center;
    }

    &-SubTitle {
        line-height: 20px;
        text-align: center;
        margin-bottom: 25px;
    }

    &-ThickWrapper {
        max-width: 500px;
        margin: auto;

        .FieldGroup-ErrorMessages {
            display: none;
        }
    }

    &-CardTypes {
        @include mobile-and-tablet {
            margin-inline: -14px;
            padding-block-end: 14px;
            margin-block-end: -14px;
            overflow-x: auto;
        }
    }

    &-CardsFieldWrapper {
        display: grid;
        align-items: center;
        grid-template-columns: repeat(3, 210px);
        grid-gap: 0 70px;
    }

    &-CardTypesWrapper {
        @include mobile {
            grid-column-gap: 20px;
            padding-inline: 14px;
        }

        .Image {
            max-height: 160px;
        }

        .Image_ratio_square {
            padding-bottom: 25px;
        }

        .Field-RadioLabel {
            display: flex;
            align-items: center;

            p {
                font-weight: 400;
                margin-bottom: 0px;
            }
        }

        .lazy-load-image-background {
            height: auto;
        }

        img {
            object-fit: contain;
            object-position: center;
            height: auto;
        }

        .Field-Wrapper {
            .Field.Field_type_radio {
                .Field-RadioLabel {
                    > input {
                        height: auto;

                        &:checked ~ * {
                            p {
                                font-weight: 500;
                            }
                        }
                    }

                    p {
                        margin-block: 0;
                    }
                }
            }
        }
    }

    &-PrimaryFieldsWrapper,
    &-NumberFieldsWrapper {
        border-bottom: 1px solid #B9B9B9;
        padding-bottom: 30px;
        margin-bottom: 30px;
    }

    &-NumberFieldsWrapper {
        >h3 {
            margin-bottom: 35px;
        }

        .Field-LabelContainer {
            min-width: 135px;

            @include desktop {
                min-width: 191px;
            }
        }

        .Field-Wrapper {
            &:not(:first-child) {
                margin-block-start: 25px;
            }
        }
    }

    &-AdditionalInfo {
        font-size: 14px;
        letter-spacing: 0.3px;
        color: #8E8E8E;
        margin-top: 25px;
        margin-bottom: 0;
    }

    .Field.Field_type_select {
        .FieldSelect-Select {
            width: 100%;
        }
    }

    .Field-Wrapper_type_number {
        .Field-ErrorMessage {
            text-align: center;
        }
    }

    .Field.Field_type_number {
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: auto 60px 40px 60px;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 0;

        @include desktop {
            grid-template-columns: auto 80px 40px 80px;
        }

        .Field-Label {
            font-size: 14px;
            font-weight: 400;
            padding: 0;
            margin-inline-end: 25px;
        }

        >input {
            height: var(--input-height);
            border: 1px solid #DFDFDF;
            border-radius: 0;
        }

        >button {
            height: var(--input-height);
            background-color: #fff;
            border: 1px solid #DFDFDF;
            border-radius: 6px;

            &:first-of-type {
                border-right: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            &:nth-of-type(2) {
                border-left: 0;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    .Field-LabelContainer {
        order: -1;
    }

    &-LabelValue {
        font-weight: 500;
        margin-left: 15px;
    }

    .Field-CheckboxLabel {
        >p {
            font-size: 12px;
            line-height: 15px;
            letter-spacing: .2px;
            color: #A8A8A8;

            >span {
                color: var(--primary-error-color);
            }

            >a {
                font-size: 12px;
                color: #3E3E3E;
                text-decoration: none;
                font-weight: 400;
            }
        }
    }

    &-ButtonsWrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;
        margin-top: 40px;

        >button {
            max-width: 335px;
            width: 100%;
            margin: auto;

            &.GiftCardsBuyCardForm-AskButton {
                background-color: $white;
                color: var(--primary-base-color);

                @include desktop {
                    margin-bottom: 60px;
                }
            }
        }
    }

    &-TotalAmount {
        &Wrapper {
            text-align: center;
        }

        &Info {
            font-size: 21px;
            line-height: 32px;
            font-weight: 500;
            color: $black;
            margin: 0;

            @include mobile {
                display: flex;
                justify-content: center;
                flex-direction: column;
                grid-gap: 5px;
                font-size: 18px;
                line-height: 25px;
            }
        }

        &Value {
            color: $default-primary-base-color;
        }

        &ShippingInfo {
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;
            color: $default-primary-base-color;
            margin: 0;
            letter-spacing: 0.2px;
            margin-left: 10px;

            @include mobile {
                font-size: 12px;
                line-height: 16px;
                margin-left: 0;
            }
        }
    }

    .GiftCardsBuyCardDetails {
        .CmsBlock-Wrapper {
            background-color: $white;
            border-radius: 10px;
            padding: 24px 18px;
            margin: 25px 0 40px;

            .mgz-element-column {
                .mgz-col-md-1 {
                    width: 20px;
                }

                .mgz-col-md-11 {
                    width: calc(100% - 20px);
                }
            }

            p {
                margin-left: 10px;
                margin-bottom: 10px;
                font-weight: 400;
                letter-spacing: .2px;
            }
        }
    }
}
