@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --field-date-range-size: 108px;
}

.FieldInputRange {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;

    &_isDisabled,
    input[disabled] {
        &,
        svg {
            cursor: default;
            opacity: 0.5;
        }
    }

    > span {
        font-size: 23px;
        color: var(--main-icons);
        line-height: 48px;
        bottom: -3px;
        margin: 0 8px;
    }

    input:not(:first-of-type) {
        margin-top: 0;
    }
}
