@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.ProductPublisherOrProducer {
    @include show-text-in-x-lines(1);

    height: 16px;
    margin-top: 10px;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: $black;
    font-weight: 300;

    a {
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        color: inherit;
        font-weight: inherit;

        &:hover {
            color: var(--link-hover);
        }
    }
}
