@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --product-slider-tab-color: #3e3d3d;
}

.CmsPage .CareerTabs-Title {
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    @include mobile {
        text-align: left;
        margin: 24px 0 19px 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.6px;
    }

    @include desktop {
        margin: 0;
    }
}

.CareerTabs {
    z-index: 0;

    &:hover {
        z-index: 1;
    }

    &-CareerTabs {
        display: flex;
        align-items: center;
        column-gap: 20px;
        width: auto;
        margin-bottom: 25px;

        @include mobile {
            flex-wrap: wrap;
            overflow-x: auto;
            margin-bottom: 18px;
            width: 100%;
            -webkit-overflow-scrolling: touch;
            column-gap: 4px;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        @include desktop {
            margin-bottom: 0;
        }
    }

    &-Placeholder {
        @include placeholder(500px);
    }

    &-Tab {
        width: 100%;
        margin-bottom: 10px;
        border-radius: 7px;
        font-weight: 600;
        font-size: 14px;
        text-align: left;
        line-height: 18px;
        letter-spacing: 0.2px;
        background: var(--product-slider-tab-bg);
        color: var(--product-slider-tab-color);
        cursor: pointer;
        padding: 14px 24px 14px 24px;
        border: 1px solid var(--product-slider-tab-color);


        @include desktop {
            width: 100%;
            max-width: 295px;
            height: 110px;
            margin-bottom: 0;
            border: 1px solid #f8f8f8;
            border-radius: 9px;
            font-weight: 300;
            font-size: 17px;
            line-height: 20px;
            color: $black;
            background-color: $white;
            letter-spacing: 0.8px;
        }

        &_isActive {
            background-color: var(--main-primary);
            border-color: var(--main-primary);
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
            color: $white;

            > div {
                color: $white;
            }
        }

        &Title {
            font-size: 17px;
            font-weight: 500;

            @include ultra-narrow-desktop {
                font-size: 15px;
            }
        }

        &Subtitle {
            margin-top: 5px;
            font-size: 12px;
        }
    }

    &-Nav {
        display: flex;
        align-items: center;

        @include mobile {
            display: block;
        }

        @include desktop {
            margin-bottom: 24px;
        }
    }

    &-Row {
        margin: 20px 0;
        
        h4 {
            .CareerTabs-ContentElement {
                font-size: 21px;
                line-height: 25px;
                letter-spacing: .5px;
                font-weight: 500;
            }  
        }
    }

    .Heading {
        font-weight: 500;
        letter-spacing: .5px;
    }

    &-ContentRow {
        margin-bottom: 12px;
        min-height: 442px;

        &:hover {
            z-index: 3;
        }
    }

    &-ContentElement { 
        margin-bottom: 0;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.9px;
    }

    &-TitleOffer {
        font-size: 21px;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: .5px;
        color: var(--main-primary);
        margin-bottom: 20px;
    }

    &-FirstParagraphOffer {
        margin-bottom: 10px;
    }

    &-SubTitleOffer {
        margin-top: 15px;
        margin-bottom: 15px;
        font-weight: 500;
    }
}
