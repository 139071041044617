@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --navigation-tabs-height: calc(72px + env(safe-area-inset-bottom));
}

.NavigationTabs {
    .hideOnScroll & {
        transform: inherit;
    }

    @media (min-width: 810px) and (max-width: 1023px) and (-webkit-min-device-pixel-ratio: 1) {
        display: block;
    }

    @include mobile-and-tablet {
        border: 0;
        background: var(--color-white);
        box-shadow: 0px -3px 4px rgba(212, 212, 212, 0.5);
        border-radius: 6px 6px 0px 0px;
    }

    &-ButtonText {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #b9b9b9;
        display: inline-block;
    }

    .Header {
        &-WishlistWrapper,
        &-MinicartWrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            min-height: 48px;
        }
    }

    &-Button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 12px 0;

        &_isActive {
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                display: block;
                background: var(--main-primary);
                border-radius: 5px 5px 0px 0px;
                height: 3px;
                width: 100%;
                max-width: 48px;
            }

            span {
                color: $black;
            }
        }

        .NavigationTabs-WishlistItemCount,
        .Header-MinicartItemCount {
            padding: 0;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--main-primary);
            border-radius: 50%;
            color: $white;
            min-width: 17px;
            min-height: 17px;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;

            @include mobile-and-tablet {
                top: 10px;
                left: calc(50% + 2px);
                right: inherit;
            }
        }
    }

    &-Icon {
        margin: 0 auto;
    }
}
