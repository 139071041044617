@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.Breadcrumbs {
    &-List {
        list-style: none;
        padding: 23px 0;

        li {
            margin-bottom: 0;
        }
    }
}
