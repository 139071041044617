@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --tab-color: #{$black};
    --tab-bg: #{$white};
    --tab-border: #E8E8E8;
    --tab-border-active: var(--main-primary);
}

.Tabs {
    z-index: 1;

    &-Tabs {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        overflow-x: auto;
        margin-bottom: 25px;
        width: 100%;
    }

    &-Tab {
        color: var(--tab-color);
        background-color: var(--tab-bg);
        text-align: center;
        letter-spacing: 0.2px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        width: 100%;
        display: block;
        padding: 11px 0;

        &::after {
            width: 100%;
            height: 3px;
            background-color: var(--tab-border);
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
        }

        &_isActive {
            font-weight: 600;
            
            &::after {
                background-color: var(--tab-border-active);
            }
        }
    }

    &-Content {
        opacity: 0;
        visibility: hidden;
        height: 0;
        overflow: hidden;

        &_isActive {
            opacity: 1;
            visibility: visible;
            height: auto;
            overflow: visible;
        }
    }
}
