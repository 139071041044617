@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --product-slider-tab-color: #{$black};
    --product-slider-tab-bg: #{white};
    --product-slider-tab-color-active: #{white};
    --product-slider-tab-border-active: #2680C3;
    --product-slider-tab-bg-active: #{$black};
    --product-slider-tab-nav-border: #f1f2f3;
}

.CmsPage .ProductSliderTabs-Title {
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    @include mobile {
        text-align: left;
        margin: 24px 0 19px 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.6px;
    }

    @include desktop {
        margin: 0;
    }
}

.ProductSliderTabs {
    &-ProductSliderTabs,
    &-Tabs {
        display: flex;
        align-items: center;
        width: auto;
        margin-bottom: 25px;
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        @include mobile {
            flex-wrap: nowrap;
            overflow-x: auto;
            height: 52px;
            margin-bottom: 18px;
            width: 100%;
            -webkit-overflow-scrolling: touch;
            column-gap: 4px;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        @include desktop {
            margin-bottom: 0;
        }
    }

    &-Placeholder {
        @include placeholder(500px);
    }

    &-Tab {
        border-radius: 25px;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.2px;
        background: var(--product-slider-tab-bg);
        color: var(--product-slider-tab-color);
        cursor: pointer;
        padding: 14px 24px 14px 24px;
        border: 1px solid var(--product-slider-tab-color);
        white-space: nowrap;

        @include desktop {
            font-weight: 300;
            font-size: 17px;
            line-height: 26px;
            color: $black;
            background-color: $white;
            letter-spacing: 0.8px;
            border-radius: 0;
            border: 0;
            border-bottom: 4px solid transparent;
        }

        &_isActive {
            background: var(--product-slider-tab-bg-active);
            color: var(--product-slider-tab-color-active);

            @include desktop {
                background-color: $white;
                color: $black;
                font-weight: 500;
                border-bottom: 4px solid var(--product-slider-tab-border-active);
            }
        }
    }

    &-Nav {
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--product-slider-tab-nav-border);

        @include mobile {
            display: block;
        }

        @include desktop {
            margin-bottom: 24px;
        }
    }

    &-ContentRow {
        margin-bottom: 12px;

        &_isDoubleSlider {
            .slick-slide > div:nth-of-type(2) {
                margin-top: 20px;
            }

            .slick-next, .slick-prev {
                top: calc(50% - 45px);
            }
        }

        .BannerWidget {
            flex: 0 0 190px;
            min-width: 190px;
            max-width: 190px;
            margin-right: 12px;
            z-index: 2;
            background-color: $white;
            display: none;

            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }

            @include desktop {
                display: block;
            }

            .Image {
                padding-bottom: 0;
            }
        }

        &.ProductSliderTabs-ContentRow_withBanner.ProductSliderTabs-ContentRow_withSlider {
            display: flex;
            align-items: flex-start;

            & > .lazyload-wrapper, & > .ProductSlider {
                max-width: 100%;

                @include desktop {
                    flex: 1 0 calc(100% - 190px);
                    max-width: calc(100% - 190px);
                    padding-right: 15px;
                }
            }
        }

        &_isButton {
            min-height: unset;
            height: auto;
            text-align: right;

            @include mobile {
                margin-top: 15px;
            }

            @include desktop {
                margin-top: -10px;
            }
        }
    }

    .ProductCard,
    .ProductSliderTabs-ContentRow {
        margin-bottom: 0;
    }

    .ProductSliderTabs-ContentRow + .ProductSliderTabs-ContentRow {
        margin-top: 20px;
    }

    .ProductSliderTabs-ContentRow >.ButtonWidget {
        @include desktop {
            position: absolute;
            top: -70px;
            right: 0;
        }
    }
}
