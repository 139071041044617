@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.react-datepicker {
    &__input-container {
        --input-border-color: rgba(0, 0, 0, 0.5);
        --input-border-color-focus: rgba(0, 0, 0, 0.5);

        svg {
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            z-index: 1;
        }

        input {
            z-index: 0;

            &[disabled] {
                opacity: 0.5;

                &,
                + svg {
                    cursor: default;
                }
            }
        }

        > label {
            font-weight: 300;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.6px;
            color: $black;
            display: block;
            margin-bottom: 13px;
            text-transform: uppercase;
        }
    }
}
