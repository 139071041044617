@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/mixins';
@import '../../../../../../../src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.SliderNextArrow {
    cursor: pointer;
    position: absolute;
    background: $white;
    border: 1px solid $default-secondary-base-color;
    inset-inline-end: 0;
    inset-block-start: 50%;
    transform: translateY(-50%);
    display: flex;
    width: 36px;
    height: 36px;
    align-items: center;
    justify-content: center;
    z-index: 2;
    border-radius: 100%;

    &_disabled,
    &:hover {
        opacity: 0.4;
    }
}
