@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.CustomerRevokeConsent {
    .Field {
        &-CheckboxLabel {
            span {
                color: var(--paragraph-color);
                font-size: var(--paragraph-font-size);
                font-style: var(--paragraph-font-style);
                font-weight: var(--paragraph-font-weight);
                line-height: var(--paragraph-line-height);
                text-transform: var(--paragraph-text-transform);
                letter-spacing: var(--paragraph-letter-spacing);
            }
        }

        textarea {
            width: 100%;
        }
    }

    &-Title {
        margin-top: 0;
    }

    &-Field {
        margin-top: 24px;

        &:last-of-type {
            margin-bottom: 24px;
        }
      
        p {
            margin-block: 8px 0;
        }
    }

    .Button {
        margin-top: 24px;
        min-width: 200px;
    }

    &-AdditionalInfo {
        font-size: 14px;
        letter-spacing: 0.3px;
        color: #8E8E8E;
        margin-top: 25px;
        margin-bottom: 0;
    }
}
