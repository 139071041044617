@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --password-neutral-color: #8a8a8a;
    --password-weak-color: #c10810;
    --password-good-color: #f26522;
    --password-strong-color: #3bb12f;
    --password-bar-height: 21px;
    --password-bar-radius: 20px;
    --password-bar-background: #f1f2f3;

    @include mobile {
        --password-bar-height: 16px;
    }
}

.PasswordStrength {
    margin-top: 24px;

    &-Label {
        padding-inline: 15px;
        position: absolute;
        top: 0;

        @include default-transition();
    }

    &-Progress {
        width: 100%;
        border: 0;
        height: var(--password-bar-height);
        border-radius: var(--password-bar-radius);
        background-color: var(--password-bar-background);

        &[value='0'],
        &[value='1'],
        &[value='2'],
        &[value='3'],
        &[value='4'] {
            + label {
                border-radius: var(--password-bar-radius);
                color: $white;
                font-weight: 400;
                font-size: 11px;
                line-height: var(--password-bar-height);
                letter-spacing: 0.6875px;
                height: var(--password-bar-height);
                text-align: center;
            }
        }

        &[value='1'] {
            + label {
                background: var(--password-weak-color);
                width: 25%;
            }
        }

        &[value='2'] {
            + label {
                background: var(--password-good-color);
                width: 50%;
            }
        }

        &[value='3'] {
            + label {
                background: var(--password-strong-color);
                width: 75%;
            }
        }

        &[value='4'] {
            + label {
                background: var(--password-strong-color);
                width: 100%;
            }
        }

        &::-webkit-progress-bar {
            border: 0;
            height: var(--password-bar-height);
            border-radius: var(--password-bar-radius);
            background-color: var(--password-bar-background);
        }

        &::-webkit-progress-value {
            border: 0;
            height: var(--password-bar-height);
            border-radius: var(--password-bar-radius);
            background-color: var(--password-bar-background);
        }

        &::-moz-progress-bar {
            border: 0;
            height: var(--password-bar-height);
            border-radius: var(--password-bar-radius);
            background-color: var(--password-bar-background);
        }

        &_noPassword {
            &[value='0'] {
                + label {
                    background: var(--password-neutral-color);
                }
            }
        }
    }
}
