@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.NewVersionPopup {
    z-index: 1000;

    @include mobile {
        display: flex;
        align-items: flex-end;
        height: 100%;
        inset-block-start: 0;

        .Popup-Heading {
            display: none;
        }
    }

    .Popup-Content {
        padding-block: 64px;

        &::after {
            display: none;
            padding-block: 0;
        }

        .Loader {
            --loader-scale: 0.6;

            width: 1em;
            height: 1em;
            position: static;

            &-Scale {
                position: static;
                margin-top: 35px;
                transform: scale(var(--loader-scale));
            }
        }
    }

    &-Heading {
        font-size: 20px;
        text-decoration: none;
        font-weight: 400;
        margin-block-start: 0;
        margin-block-end: 12px;

        @include mobile {
            margin-block-end: 14px;
        }

        @include desktop {
            display: none;
        }
    }

    &-Buttons {
        margin-block-start: 12px;

        @include mobile {
            margin-block-start: 14px;
        }

        @include desktop {
            display: flex;
            justify-content: space-between;
        }
    }

    &-ReloadButton {
        @include mobile {
            width: 100%;
        }
    }

    &-DismissButton {
        padding-left: var(--button-padding);
        padding-right: var(--button-padding);
        height: var(--button-height);

        @include mobile {
            width: 100%;
            margin-block-start: 5px;
        }
    }

    &-Content {
        text-align: center;

        @include desktop {
            max-width: 500px;
        }
    }

    .Popup {
        &-CloseBtn {
            display: none;
        }

        &-Heading {
            margin-block-end: 12px;

            @include mobile {
                margin-block-end: 14px;
            }
        }
    }
}
