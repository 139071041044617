@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.CmsSlider {
    .Slider {
        &-Image {
            display: block;
        }
    }
}

.Slider {
    &-SliderContainer {
        ul.slick-dots {
            position: absolute;
            inset-block-end: 32px;
            height: 32px;

            li.slick-active {
                width: 16px;
                height: 16px;
            }

            li {
                margin: 0 16px 0 0;
                width: 10px;
                height: 10px;
                background-color: white;

                &:last-child {
                    margin: 0;
                }

                &::after {
                    width: 32px;
                    height: 3px;
                    content: '';
                    position: absolute;
                    inset-inline-start: 0;
                    inset-block-end: 0;
                }
            }
        }
    }
    
    &-Content {
        background: rgba(255, 255, 255, 0.8);
        padding: 24px 0;
        width: 100%;

        h5 {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: $black;
            margin-block-end: 5px;
        }

        h2 {
            font-weight: 400;
            font-size: 24px;
            line-height: 30px;
            text-align: start;
            color: $black;
            margin-block-start: 5px;
            margin-block-end: 5px;
        }
    }

    &-Button {
        margin-block-start: 16px;
    }

    
    &-Page {
        display: none;
    }
}

[dir='ltr'] .Slider-Image {
    img {
        width: 100%;
    }
}

[dir='ltr'] .Slider .slick-dots {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
