@mixin page-container {
    max-width: $page-max-width;
    /* stylelint-disable-next-line csstools/use-logical */
    padding-left: $page-padding-left-mobile;
    /* stylelint-disable-next-line csstools/use-logical */
    padding-right: $page-padding-right-mobile;
    /* stylelint-disable-next-line csstools/use-logical */
    margin-left: auto;
    margin-right: auto;
}

@mixin default-transition($properties: all) {
    transition: $properties 200ms cubic-bezier(0.47, 0, 0.745, 0.715);
}

@mixin scrollbar($scrollbar-thumb-color: #c4c4c4, $scrollbar-rail-color: #ebebeb) {
    $scrollbar-width: 6px;
    $scrollbar-width-moz: thin;
    $scrollbar-border-radius: 10px;

    // Chrome & Safari (WebKit)

    &::-webkit-scrollbar {
        background: $scrollbar-rail-color;
        width: $scrollbar-width;
        height: $scrollbar-width;
    }

    &::-webkit-scrollbar-track {
        background: $scrollbar-rail-color;
    }

    &::-webkit-scrollbar-thumb {
        background: $scrollbar-thumb-color;
        border-radius: $scrollbar-border-radius;
    }

    // Firefox (Gecko)
    scrollbar-color: $scrollbar-thumb-color $scrollbar-rail-color;
    scrollbar-width: $scrollbar-width-moz;
}

@mixin floating-label {
    & + .Field-LabelContainer {
        background: $white;
        padding: 0 16px 0 8px;
        top: -8px;
        left: 14px;

        label {
            color: var(--input-color);
            font-size: 12px;
            letter-spacing: 0.2px;
        }

        .Field-Label_isRequired {
            color: var(--input-color);
            font-size: 12px;
            margin-left: 2px;
        }
    }
}

@mixin account-mobile-route-styles {
    :root {
        --account-mobile-route-padding-top: 80px;
        --account-mobile-route-padding-bottom: 115px;
        --account-mobile-route-additional-space-top: 18px;
    }
}

@mixin account-mobile-route-heading-btn-styles {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #000000;
    padding: 0;
    margin: 0;
    width: auto;
    min-width: unset;
}

@mixin show-text-in-x-lines($lines: 2) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
    overflow: hidden;
    white-space: initial;
    text-overflow: initial;
}

@mixin placeholder($height) {
    background-image: var(--placeholder-image);
    background-size: var(--placeholder-size);
    animation: var(--placeholder-animation);
    margin: 50px auto;
    height: $height;
    max-width: calc(var(--content-wrapper-width));
    width: 100%;
}

@mixin disable-select() {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin keyframes-fadeInLeft($transform1: translateX(-40px), $transform2: translateX(0)) {
    @-webkit-keyframes fadeInLeft {
        0% {
            opacity: 0;
            -webkit-transform: $transform1;
        }

        100% {
            opacity: 1;
            -webkit-transform: $transform2;
        }
    }

    @-moz-keyframes fadeInLeft {
        0% {
            opacity: 0;
            -moz-transform: $transform1;
        }

        100% {
            opacity: 1;
            -moz-transform: $transform2;
        }
    }

    @-o-keyframes fadeInLeft {
        0% {
            opacity: 0;
            -o-transform: $transform1;
        }

        100% {
            opacity: 1;
            -o-transform: $transform2;
        }
    }

    @keyframes fadeInLeft {
        0% {
            opacity: 0;
            transform: $transform1;
        }

        100% {
            opacity: 1;
            transform: $transform2;
        }
    }
}

@mixin animation($name, $fillMode: both, $duration: 1s) {
    -webkit-animation-name: $name;
    -moz-animation-name: $name;
    -o-animation-name: $name;
    animation-name: $name;
    -webkit-animation-fill-mode: $fillMode;
    -moz-animation-fill-mode: $fillMode;
    -ms-animation-fill-mode: $fillMode;
    -o-animation-fill-mode: $fillMode;
    animation-fill-mode: $fillMode;
    -webkit-animation-duration: $duration;
    -moz-animation-duration: $duration;
    -ms-animation-duration: $duration;
    -o-animation-duration: $duration;
    animation-duration: $duration;
}


@mixin animation-delay($value: 0.5s) {
    -webkit-animation-delay: $value;
    -moz-animation-delay: $value;
    animation-delay: $value;
}

@mixin size($width, $minWidth: $width, $maxWidth: $width) {
    width: $width;
    min-width: $minWidth;
    max-width: $maxWidth;
}

@mixin slickSlideBetween($value: 0px) {
    .slick-slide {
        padding: 0 $value;
        box-sizing: border-box;
    }

    .slick-list {
        margin: 0 $value * -1;
    }
}
