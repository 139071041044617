@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.CheckoutSteps {
    background: #F5F5F5;
    counter-reset: checkoutSteps;
    display: flex;
    justify-content: center;
    padding: 18px 0;

    &-Step {
        &,
        > a,
        > div,
        button {
            display: flex;
            align-items: center;
        }

        > a,
        button {
            cursor: pointer;
        }

        &_active {
            .CheckoutSteps-StepCount {
                background: $red;
            }
        }

        &Count {
            counter-increment: checkoutSteps;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            width: 28px;
            height: 28px;
            background: #B9B9B9;

            @include desktop {
                margin-right: 11px;
            }

            @include mobile {
                margin-right: 16px;
            }

            &::after {
                content: counter(checkoutSteps);
                font-size: 14px;
                font-weight: 500;
                color: #ffffff;
            }
        }

        &Title {
            font-size: 12px;
            font-weight: 400;
            color: #000000;
            letter-spacing: .2px;
            margin-right: 24px;
        }

        &::after {
            display: block;
            content: '';
            height: 1px;
            background: #B9B9B9;
            width: 100px;

            @include desktop {
                max-width: 6vw;
                margin-right: 24px;
            }

            @include mobile {
                max-width: 13vw;
                margin-right: 16px;
            }
        }

        &:last-of-type {
            &::after {
                display: none;
            }

            .CheckoutSteps-StepTitle {
                margin-right: 0;
            }

            @include mobile {
                .CheckoutSteps-StepCount {
                    margin-right: 0;
                }
            }
        }
    }
}
