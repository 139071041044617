@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.Newsletter {
    --newsletter-background: #F5F5F5;

    overflow: hidden;

    &-Container {
        background: var(--newsletter-background);
        padding: 30px 0;

        @include desktop {
            padding: 55px 0 45px;
            width: 100vw;
            min-height: 285px;
        }
    }

    .ContentWrapper {
        @include desktop {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 auto;
            max-width: 1040px;
        }
    }

    &-InfoWrapper,
    &-FormWrapper {
        @include desktop {
            width: 50%;
        }
    }

    &-InfoWrapper {
        @include mobile {
            margin-bottom: 20px;
        }
    }

    &-Info .CmsBlock-Wrapper {
        margin-bottom: 15px;
        padding: 0 12px;

        > p {
            font-weight: 300;
            font-size: 14px;
            line-height: 17px;
            color: $black;
            letter-spacing: 0.6px;
            margin: 15px 0 0 0;

            @include desktop {
                font-size: 17px;
                line-height: 20px;
                letter-spacing: 0.8px;
            }

            &:first-of-type {
                @include desktop {
                    margin-top: 0;
                }
            }

            svg {
                margin-right: 15px;
                top: 2px;
            }
        }

        .Newsletter-SectionTitle,
        > h1,
        > h2,
        > h3,
        > h4,
        > h5 {
            letter-spacing: 0.8px;
            font-weight: 600;
            font-size: 25px;
            line-height: 130%;
            color: $black;
            margin: 0 0 15px;

            @include desktop {
                font-size: 30px;
            }

            > span {
                color: $white;
                background: var(--main-primary);
                display: inline-block;
            }
        }

        @include desktop {
            margin-bottom: 0;
            text-align: left;
            padding: 0 30px 0 0;
        }
    }

    &-Image {
        margin: 0;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 0;
        opacity: 0.25;

        @include desktop {
            top: 33px;
            left: 55px;
            transform: scale(2.1);
        }

        @include mobile {
            max-height: 100px;
        }
    }

    .NewsletterSubscription {
        @include wide-desktop {
            margin: 0 0 0 auto;
        }
    }
}
