@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

$select-option-height: 35px !default;

:root {
    --dynamic-select-width: 180px;
}

[dir='ltr'] .FieldSelect {
    .helper-element {
        position: absolute;
        top: 0;
        left: -9999px;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.2px;
    }

    &-Select {
        width: var(--dynamic-select-width);
        padding-right: 0;

        &:disabled {
            &,
            + .ChevronIcon {
                opacity: 0.5;
            }
        }
    }

    &-Option {
        padding-inline-start: 14px;
        padding-left: 30px;
        padding-right: 14px;

        &_isHovered {
            @include desktop {
                background: var(--main-text-and-icons-bg);
            }
        }

        &_isActive {
            font-weight: 600;
        }
    }

    &-Options {
        border-width: 1px;

        .ChevronIcon {
            right: 14px;
        }

        &Wrapper {
            @include desktop {
                display: none;

                &_isExpanded {
                    display: block;
                    max-height: $select-option-height * 6 + 5;
                }
            }
        }

        &_isExpanded {
            display: block;

            @include desktop {
                border-radius: 6px;
                margin-top: 2px;
                border-color: rgba(0, 0, 0, .1);
            }

            & > div {
                @include scrollbar;
            }
        }
    }

    &-Tick {
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
