@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.lazy-load-image-background {
    display: block;
    width: 100% !important;
    height: 100%;
    will-change: opacity;
}

.Image:not(.Image_ratio_custom) .lazy-load-image-background {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
}

.ProductCard {
    &-Picture,
    &-Figure,
    &-FigureAlternative {
        .lazy-load-image-background {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

.Header-LogoWrapper {
    .lazy-load-image-background {
        // stylelint-disable-next-line
        display: flex !important;
        align-items: center;
    }
}
