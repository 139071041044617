@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.Agreements {
    &-Item {
        &:not(:first-of-type) {
            margin-block-start: 14px;
        }

        &Content {
            margin-block-start: 14px;

            & , * {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.2px;
            }
        }

        .ShowMoreLessText {
            &:not(:first-child) {
                margin-block-start: 16px;
            }
        }

        .Field-Wrapper:not(:first-of-type) {
            margin-block: 0;
        }

        .Field-CheckboxLabel {
            &, & span, & a {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.2px;
            }

            & a {
                color: $default-primary-base-color;
            }
        }
    }
}
