@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --product-slider-border-color: #dfdfdf;
}

.CmsPage-Content .ProductSlider h2 {
    @include mobile {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.6px;
        text-align: left;
    }
}

.ProductSlider {
    --product-cart-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.1), 0 6px 16px rgba(0, 0, 0, 0.1), 0 9px 28px -10px rgba(0, 0, 0, 0.1);

    $productSliderOverlayTopLeft: 0 -20px;
    $productSliderOverlayTopRight: 100% -20px;
    $productSliderOverlayBottomRight: 100% calc(100% + 300px);
    $productSliderOverlayBottomLeft: 0 calc(100% + 300px);

    clip-path: polygon(#{$productSliderOverlayTopLeft}, #{$productSliderOverlayTopRight}, #{$productSliderOverlayBottomRight}, #{$productSliderOverlayBottomLeft});
    min-height: 442px;

    &_isDoubleSlider {
        min-height: 997px;

        @include mobile {
            min-height: 954px;
        }
    }

    &-Placeholder {
        @include placeholder(492px);

        margin: 0 auto;

        &_isDoubleSlider {
            @include placeholder(970px);

            @include mobile {
                @include placeholder(954px);
            }

            margin: 0 auto;
        }
    }

    &-Content {
        padding-inline: 15px;

        .slick-active-last {
            .ProductCard {
                border-right-color: transparent;
            }
        }
    }

    &:hover {
        z-index: 1;
    }
}

[dir="ltr"] .ProductSlider {
    &-Heading {
        font-weight: 500;
        font-size: 21px;
        line-height: 25px;
        letter-spacing: 0.5px;
        color: $black;

        &Wrapper {
            display: flex;
            align-items: center;
        }
    }

    &-Navigation {
        display: flex;
        align-items: center;
        column-gap: 10px;
        padding-left: 18px;

        @include mobile {
            display: none;
        }

        & > div {
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            border-radius: 100%;
            width: 32px;
            height: 32px;
            background-color: #F1F2F3;
            cursor: pointer;

            svg {
                height: 40px;
            }
        }
    }

    .slick-slider {
        margin-left: -10px;
        margin-right: -10px;
    }

    .slick-list {
        overflow: visible;
        z-index: 1;

        @include mobile {
            margin-inline: -10px;
        }
    }

    .slick-slide {
        @include mobile {
            .ProductCard {
                padding-inline: 10px;
            }
        }
    }

    &-Container {
        display: flex;
        width: 100%;

        .BannerWidget {
            min-width: 190px;
            max-width: 190px;
            border-radius: 9.5px;
            margin-right: 12px;
            z-index: 2;
            background-color: $white;
            display: none;

            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                background-color: $white;
                position: absolute;
                top: 0;
                left: -50px;
            }

            @include desktop {
                display: block;
            }

            .Image {
                padding-bottom: 0;
            }
        }

        .ProductSlider-Content {
            max-width: 100%;

            @include desktop {
                max-width: calc(100% - 190px);
                padding-right: 15px;
            }
        }
    }

    .slick-prev,
    .slick-next {
        z-index: 9;

        @include default-transition();

        &.slick-disabled {
            opacity: 0;
        }

        &::before {
            display: none;
        }
    }

    .slick-prev {
        left: -8px;
    }

    .slick-next {
        right: 8px;
    }

    .ProductCard {
        padding: 0;

        &:hover {
            @include desktop {
                border: 1px solid var(--product-cart-border-color);
                box-shadow: var(--product-cart-box-shadow);

                &,
                .ProductCard-FigureReview {
                    border-radius: var(--product-cart-border-radius) var(--product-cart-border-radius) 0 0;
                }

                .ProductCard-VisibleOnHover {
                    border-radius: 0 0 var(--product-cart-border-radius) var(--product-cart-border-radius);
                    box-shadow: var(--product-cart-box-shadow);
                }
            }
        }
    }

    &_isPromotedVariant {
        padding-block: 30px;

        .slick-track {
            display: flex;
        }

        .slick-slide {
            height: auto;

            & > div {
                display: flex;
                height: 100%;
            }
        }

        .slick-list {
            @include mobile-small {
                // stylelint-disable-next-line
                padding: 0 !important;
            }
        }

        .slick-next,
        .slick-prev {
            transform: translate(0, calc(-50% - 25px));
        }

        .ProductSlider-Content {
            @include desktop {
                max-height: 584px;
            }

            .ProductCard-Labels {
                @include mobile-small {
                    flex-wrap: wrap;
                }
            }
        }

        .ProductPrice del.ProductPrice-HighPrice {
            margin: 0 4px;
        }

        .ProductPrice-PriceValue {
            font-size: 16px;
        }

        .ProductPrice-HighPrice {
            font-size: 12px;
        }

        .ProductCard {
            border: 0;
            margin: 0;
            border-radius: 0;
            padding-block: 0;
            border-left: 1px solid var(--product-slider-border-color);
            padding-left: 24px;
            padding-right: 4px;

            @include tablet {
                border-left-color: transparent;
            }

            @include mobile {
                border-left-color: transparent;
            }

            @include mobile {
                padding-right: 24px;
            }

            &-Footer {
                .ProductCard-AddToCart {
                    --button-background: #{$black};
                    --button-border: #{$black};
                    --button-hover-border: #{$grey3};
                    --button-hover-background: #{$grey3};
                    --button-hover-padding: 20px;

                    margin-block: 0;
                    width: 100%;
                    max-width: 161px;
                    grid-gap: 11px;
                    letter-spacing: .2px;
                    height: 40px;

                    svg {
                        width: 20px;
                        height: 20px;
                        left: 0;
                        margin: 0;
                    }
                }
            }

            &:hover {
                @include desktop {
                    border: 0;
                    border-left: 1px solid var(--product-slider-border-color);
                    box-shadow: none;

                    &,
                    .ProductCard-FigureReview {
                        border-radius: 0;
                    }

                    .ProductCard-VisibleOnHover {
                        border-radius: 0;
                    }

                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3) {
                        border-top: 0;
                    }

                    &:nth-child(even) {
                        border-left: 1px solid var(--product-slider-border-color);
                        border-right: 1px solid var(--product-slider-border-color);
                    }
                }
            }
        }
    }

    &_isLightVariant {
        .slick-list {
            @include mobile-small {
                // stylelint-disable-next-line
                padding: 0 !important;
            }
        }

        .slick-next,
        .slick-prev {
            transform: translate(0, calc(-50% - 50px));
        }

        .ProductSlider-Content {
            border: 1px solid var(--product-slider-border-color);
            border-radius: 9.5px;
            overflow: hidden;

            @include desktop {
                max-height: 584px;
            }

            .ProductCard {
                @include mobile-small {
                    max-height: 375px;
                    min-height: 375px;
                    max-width: 100%;
                }
            }

            .ProductCard-Labels {
                @include mobile-small {
                    flex-wrap: wrap;
                }
            }
        }

        .ProductPrice del.ProductPrice-HighPrice {
            margin: 0 4px;
        }

        .ProductPrice-PriceValue {
            font-size: 16px;
        }

        .ProductPrice-HighPrice {
            font-size: 12px;
        }

        .ProductCard-FigureReview {
            max-width: 120px;
            margin: 0 auto;
        }

        .ProductCard {
            border: 0;
            margin: 0;
            border-radius: 0;
            border-left: 1px solid var(--product-slider-border-color);
            padding: 16px;

            &:hover {
                @include desktop {
                    border: 0;
                    border-left: 1px solid var(--product-slider-border-color);
                    box-shadow: none;

                    &,
                    .ProductCard-FigureReview {
                        border-radius: 0;
                    }

                    .ProductCard-VisibleOnHover {
                        border-radius: 0;
                    }

                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3) {
                        border-top: 0;
                    }

                    &:nth-child(even) {
                        border-left: 1px solid var(--product-slider-border-color);
                        border-right: 1px solid var(--product-slider-border-color);
                    }
                }
            }
        }

        .slick-slide {
            >div {
                padding: 0;

                &:nth-of-type(1) {
                    border-bottom: 1px solid var(--product-slider-border-color);

                    .ProductCard {
                        border-top: 0;
                    }
                }
            }
        }
    }

    &_notEnoughItemsLength {
        @include desktop {
            .slick-track {
                margin-left: 0;
            }
        }
    }
}
