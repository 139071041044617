@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.MapSidebar {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 30px;
    padding-left: 20px;
    overflow: hidden;

    @include desktop {
        width: 30%;
        margin-top: 0;

        ::-webkit-scrollbar {
            width: 4px;
        }

        ::-webkit-scrollbar-track {
            border-radius: 5.5px;
        }
           
        ::-webkit-scrollbar-thumb {
            background: black;
            border-radius: 5.5px;
        }
    }

    &-Locations {
        max-height: 425px;
        padding-right: 5px;
        overflow: auto;

        ul {
            li {
                padding: 12px 24px 12px 0;
                border-top: 1px solid $gray-light;
            }
        }
    }

    &-LocationText {
        font-weight: 400;
    }

    &-Search {
        margin: 5px 0 20px;

        input {
            width: 100%;
            padding: 13px 48px 13px 16px;
            border-radius: 100px;
            font-size: 12px;
            background: $grey;
            border-color: transparent;
        }

        svg {
            position: absolute;
            top: 14px;
            right: 20px;
            z-index: 2;
            pointer-events: none;
        }
    }

    &-FindLocation {
        display: flex;
        align-items: center;
        grid-gap: 10px;
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;
        letter-spacing: .2px;
        margin-bottom: 20px;
    }

    &-Location {
        cursor: pointer;
        font-size: 12px;
        font-weight: 400;
        list-style: none;
        margin-bottom: 0;

        &_type {
            &_HiddenInfo {
                max-height: 0;
                overflow: hidden;
                transition: max-height .2s ease-in-out;
            }
        }

        &_isExpanded {
            .MapSidebar {
                &-Location_type_HiddenInfo {
                    max-height: 500px;
                }

                &-ArrowIcon {
                    svg {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        p {
            display: -webkit-box;
            max-height: 45px;
            /* fallback */
            line-height: 15px;
            /* fallback */
            overflow: hidden;
            text-overflow: clip;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            /* number of lines to show */
        }

        .OpeningHours {
            font-weight: bold;
            font-weight: 600;
        }

        .AdditionalInfo {
            font-weight: 400;
            color: #DD151E;
        }
    }

    &-LocationLabel {
        img {
            display: block;
            width: auto;
            margin-left: auto;
        }
    }

    &-ArrowIcon {
        position: absolute;
        top: 12px;
        right: 12px;

        svg {
            transition: transform .2s ease-in-out;
        }
    }
}
