@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

[dir='ltr'] .MultipleSingleProductWidget .SingleProductWidget,
[dir='ltr'] .SingleProductWidget {
    flex: 1;
    display: block;
    margin: 0 auto;

    @include mobile {
        margin: 0 auto 0;
    }

    &:not(.SingleProductWidget_isGridVariant) {
        .ProductPublisherOrProducer {
            @include desktop {
                text-align: left;
            }

            margin-bottom: 10px;
        }

        @include desktop {
            .SingleProductWidget-Name {
                max-width: 290px;
            }
        }
    }

    .OmnibusPriceInfo {
        display: flex;
        align-items: center;
        min-height: 32px;
        max-height: 32px;
        max-width: 200px;
        margin-inline: auto;
        white-space: nowrap;
    }

    .ProductWishlistButton {
        --wishlist-heart-size: 32px;

        position: absolute;
        top: 24px;
        right: 12px;

        &-Button.Button {
            width: var(--wishlist-heart-size);

            > svg {
                height: var(--wishlist-heart-size);
                width: var(--wishlist-heart-size);
            }

            &:not([disabled]) {
                &:hover {
                    height: var(--wishlist-heart-size);
                    padding: 0;
                    background-color: var(--button-hover-background);

                    > svg {
                        path {
                            fill: var(--white);
                        }
                    }
                }
            }
        }
    }

    &:not(&_isGridVariant):not(&_isBannerVariant) {
        .AddToCart {
            padding-left: var(--outline-button-padding);
            padding-right: var(--outline-button-padding);
        }
    }

    &_isGridVariant {
        overflow-y: visible;
        width: 100%;

        .ProductPublisherOrProducer {
            margin-top: 0;
            margin-bottom: 10px;
        }

        .AddToCart {
            padding-left: var(--outline-button-padding);
            padding-right: var(--outline-button-padding);
        }

        .ProductWishlistButton {
            top: 10px;
            right: 10px;
        }

        .SingleProductWidget-ShortDescription {
            text-align: center;
        }

        .SingleProductWidget-PictureWrapper {
            margin-bottom: 24px;
        }
    }

    &_isBannerVariant {
        border-radius: 10px;
        width: 100%;
        padding: 35px 24px;
        margin: 0;
        height: 100%;

        @include mobile {
            min-height: 388px;
            padding: 24px 28px;
        }

        .ProductWishlistButton {
            top: 10px;
            right: 10px;

            &-Button.Button {
                &:not([disabled]) {
                    fill: var(--white);
                }
            }
        }

        +.SingleProductWidget_isBannerVariant {
            margin-left: 20px;

            @include mobile {
                margin-left: 0;
                margin-top: 25px;
                padding: 24px 28px;
            }
        }

        .SingleProductWidget-PictureWrapper {
            width: 162px;
            height: 216px;
        }
    }

    &-Labels {
        display: flex;
        align-items: center;
        column-gap: 8px;
        margin-top: 10px;
        min-height: 22px;

        @include mobile {
            justify-content: center;
        }
    }

    &-Placeholder {
        @include placeholder(450px);
    }

    .AddToCart {
        white-space: nowrap;
        column-gap: 10px;

        @include desktop {
            max-width: 280px;
            width: 100%;
        }

        svg {
            width: 22px;
            height: 20px;
            margin-inline: 0;
        }
    }

    .ProductPrice {
        @include mobile {
            justify-content: center;
        }
    }

    &-PictureWrapper {
        width: 230px;
        max-width: 100%;
        margin-right: 24px;
        margin-left: 12px;

        @include mobile {
            margin: 0 auto 12px;
        }

        .Image_ratio_custom {
            width: 100%;
            padding-bottom: 120%;
            height: 100%;
            max-width: 100%;

            .lazy-load-image-background {
                position: absolute;
                inset: 0;
                height: 100%;
                width: 100%;
                text-align: center;
            }

            img {
                width: auto;
                max-width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
    }

    &-AvailabilityInfo {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 47px;
        margin-top: -10px;

        .AvailabilityInfo-Item:last-child {
            margin-block-end: 0;
        }
    }

    &-Title {
        margin-block: 0;

        @include mobile {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.6px;
        }
    }

    &:not(&_isBannerVariant) {
        .SingleProductWidget-Name {
            min-height: 64px;

            @include mobile {
                min-height: 36px;
            }
        }
    }

    &-Name {
        font-weight: 500;
        font-size: 21px;
        line-height: 32px;
        letter-spacing: 0.8px;
        color: $black;
        display: block;


        @include show-text-in-x-lines(2);

        @include mobile {
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.2px;
        }
    }

    &-TitleContainer {
        width: 100%;
    }

    &-ShortDescription {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: $black;
        margin: 14px 0 32px;

        @include show-text-in-x-lines(5);

        @include desktop {
            min-height: 100px;
        }

        @include mobile {
            font-weight: 300;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.2px;
            margin: 12px 0 22px;
        }
    }

    &-ProductLabels {
        margin: 10px 0 14px;
    }

    &-Content {
        display: flex;
        justify-content: space-between;
        padding: 24px 12px;

        &Wrapper {
            flex: 1;
        }

        @include mobile {
            flex-direction: column;
            padding: 16px;
            text-align: center;
        }
    }

    &-GridContent {
        flex-direction: column;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 45px 15px 25px;
        border: 1px solid transparent;
        overflow-y: visible;
        margin-bottom: 10px;

        @include desktop {
            &:hover {
                border-radius: 9.5px;
                border: 1px solid var(--product-cart-border-color);
                box-shadow: var(--product-cart-box-shadow);
            }
        }

        .SingleProductWidget-PictureWrapper {
            margin-inline: 0;
        }

        .SingleProductWidget-Name {
            text-align: center;
        }

        .CmsPage {
            overflow-y: visible;
        }
    }

    &-LabelContent {
        min-height: 22px;
        margin-bottom: 10px;
    }

    &-BannerTitle {
        font-weight: 600;
        font-size: 30px;
        line-height: 39px;
        letter-spacing: 0.8px;
        color: $white;
        margin-top: 0;

        @include desktop {
            min-height: 78px;
        }

        @include show-text-in-x-lines(2);

        @include mobile {
            font-weight: 500;
            font-size: 18px;
            line-height: 25px;
            text-align: center;
            letter-spacing: 0.5px;
        }
    }

    &-ProductAuthors {
        margin: 5px 0 7px;
    }

    &-Author {
        line-height: 21px;
    }

    &-BannerSubtitle {
        color: $white;
        font-weight: 500;
        letter-spacing: 0.8px;

        @include show-text-in-x-lines(2);

        @include desktop {
            font-size: 17px;
            line-height: 20px;
            min-height: 40px;
        }
    }

    &-BannerContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: 100%;

        @include mobile {
            flex-direction: column;
        }

        > div {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include desktop {
                order: -1;
            }
        }

        .AddToCart {
            margin-top: 36px;
            border: 1px solid $black;

            svg {
                path {
                    fill: $black;
                }
            }

            @include mobile {
                margin-top: 18px;
            }

            &:hover {
                border: 1px solid $black;
            }
        }

        .SingleProductWidget-PictureWrapper {
            margin-right: 0;

            @include mobile {
                margin: 0 auto 12px;
            }
        }

        .ProductPrice-PriceValue {
            font-weight: 500;
            font-size: 25px;
            line-height: 32px;
            letter-spacing: 0.8px;
            color: $white;

            @include desktop {
                font-weight: 600;
                font-size: 30px;
                line-height: 45px;
            }
        }

        .ProductPrice_hasDiscount {
            align-items: center;

            > div {
                &:first-of-type {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;

                    @include mobile {
                        flex-direction: row;
                    }
                }
            }

            @include mobile {
                flex-direction: column;
                align-items: center;
            }


            .ProductPrice-HighPrice {
                font-weight: 300;
                font-size: 25px;
                line-height: 38px;
                letter-spacing: 0.8px;
                text-decoration-line: line-through;
                color: $white;
                margin-left: 15px;

                @include desktop {
                    margin: 0;
                    font-size: 26px;
                    line-height: 39px;
                    letter-spacing: 0.7px;
                }
            }
        }

        .ProductLabel-Wrapper {
            height: 100%;
            display: flex;
            align-items: center;

            @include mobile {
                margin: 10px auto 5px;
            }

            .ProductLabel_type_sale {
                mix-blend-mode: normal;
                opacity: 0.15;
                color: $white;
                background: transparent;
                border: none;
                font-size: 57px;
                font-weight: 700;

                @include mobile {
                    font-size: 25px;
                    letter-spacing: 1.39px;
                }
            }
        }
    }
}
