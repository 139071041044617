@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@mixin radio-after-styles {
    box-shadow: none;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 50%;
    // stylelint-disable-next-line
    left: 50% !important;
    transform: translate(-50%, -50%);
}

.Field {
    --checkbox-border-radius: var(--imported_checkboxes_border_radius, 2px);
    --checkbox-border-color: var(--imported_checkboxes_border_color, #{$grey4});
    --checkbox-border-color-active: var(--imported_checkboxes_border_color, #{$black});
    --checkbox-height: var(--imported_checkboxes_height, 18px);
    --checkbox-width: var(--imported_checkboxes_width, 18px);

    margin-block-start: 0;

    @include mobile {
        margin-block-start: 0;
    }
   
    &_hidden {
        display: none;
    }

    &-PasswordIcon {
        margin: 0;
        cursor: pointer;
        position: absolute;
        height: 20px;
        width: 20px;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        z-index: 9;

        svg {
            height: 100%;
            width: 100%;
        }
    }

    &-SubLabelContainer {
        margin-top: 8px;
        text-align: left;
    }

    &-SubLabel {
        font-weight: 400;
    }

    &Group-Wrapper .Field-Wrapper {
        margin-block-start: 24px;
    }

    &-Wrapper {
        &:not(:first-of-type) {
            margin-block-start: 24px;
        }
    }


    &-ErrorMessages {
        letter-spacing: 0.5px;
        margin-top: 8px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        padding: 0;
    }

    &-Label {
        &_isRequired {
            color: lighten(#3e3d3d, 50%);
            font-size: 14px;
        }
    }

    &_type {
        &_password {
            input::-webkit-credentials-auto-fill-button {
                position: absolute;
                right: 45px;
            }
        }

        &_text,
        &_normalNumber,
        &_textarea,
        &_email,
        &_password,
        &_date,
        &_time,
        &_datetime,
        &_datetime-local,
        &_file,
        &_select {
            &.Field_isValid {
                input,
                textarea,
                select {
                    border: 1px solid var(--input-border-color);
                }
            }
        }

        &_text,
        &_normalNumber,
        &_textarea,
        &_email,
        &_password,
        &_select,
        &_range {
            textarea,
            input {
                caret-color: var(--input-color);

                &::placeholder {
                    color: transparent;
                }
            }

            .Field-LabelContainer {
                position: absolute;
                top: 15px;
                left: 14px;
                z-index: 2;
                pointer-events: none;
                transition: 0.3s;

                label {
                    font-weight: 400;
                    font-size: 14px;
                    letter-spacing: 0.6px;
                    color: $grey4;
                    padding: 0;
                    line-height: 17px;
                }

                .Field-Label_isRequired {
                    line-height: 17px;
                }

                .ChevronIcon {
                    width: 23px;
                }
            }

            input:not(:placeholder-shown),
            input:focus,
            textarea:not(:placeholder-shown),
            textarea:focus,
            .FieldSelect {
                @include floating-label;
            }

            input,
            textarea,
            .FieldSelect {
                &:disabled {
                    .ChevronIcon {
                        opacity: 0.5;
                    }

                    + .Field-LabelContainer {
                        opacity: 0.5;
                        cursor: default;
                    }
                }
            }
        }

        &_checkbox,
        &_radio {
            label {
                &:first-of-type {
                    display: inline-flex;
                    align-items: flex-start;
                }

                & > span {
                    display: block;
                    z-index: 10;
                }

                a {
                    z-index: 10;
                    text-decoration: underline;
                    font-weight: bold;
                    color: $black;
                }
            }
        }
    }

    &-SwitcherLabel {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.5px;

        .switcher-control {
            display: inline-flex;
            margin-inline-start: 16px;
            height: 20px;
            width: 37px;
            border-radius: 100px;
            background-color: $grey4;
            cursor: pointer;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                height: 14px;
                width: 14px;
                transform: translateY(-50%) translateX(3px);
                background-color: $white;
                border-radius: 100%;
                will-change: transform;
            }
        }

        [type='checkbox']:checked + .switcher-control {
            background-color: $default-primary-base-color;

            &::before {
                transform: translateY(-50%) translateX(20px);
            }
        }
    }

    &-CheckboxLabel,
    &-RadioLabel {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.6px;

        &:not([class*='_isDisabled']):hover {
            cursor: pointer;

            @include desktop {
                color: $black;
            }
        }

        &_isDisabled {
            color: var(--main-text-and-icons-not-active);
        }
    }

    [type='checkbox'],
    [type='radio'] {
        &:hover:not(:disabled) + .input-control {
            border-color: var(--checkbox-border-color);
        }
    }

    [type='checkbox'] + .input-control,
    [type='radio'] + .input-control {
        margin-inline-end: 12px;
        border: 1px solid var(--checkbox-border-color);
        width: var(--checkbox-width);
        min-width: var(--checkbox-width);
        height: var(--checkbox-height);
        min-height: var(--checkbox-height);

        &:disabled {
            opacity: 1;
        }
    }

    [type='checkbox'] {
        + .input-control {
            border-radius: 2px;
        }

        &:checked {
            + .input-control {
                &::after {
                    --checkmark-color: #{$black};
                }
            }
        }
    }

    [type='radio'] {
        + .input-control {
            &::after {
                @include radio-after-styles;

                background: transparent;
            }
        }

        &:hover:not(:disabled) + .input-control {
            border-color: var(--checkbox-border-color);

            @include desktop {
                &::after {
                    @include desktop {
                        @include radio-after-styles;

                        background: var(--secondary-dark-color);
                    }
                }
            }
        }

        &:checked {
            + .input-control {
                border-color: var(--checkbox-border-color);

                &::after {
                    @include radio-after-styles;

                    background: $black;
                }
            }
        }
    }
}
