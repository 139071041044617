@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.Field.Field_type_number {
    display: flex;
    border: 1px solid var(--main-text-and-icons-bg);
    border-radius: 16px;
    width: 100px;
    height: 32px;
    padding: 0;

    > input,
    > button {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.6px;
        color: $black;
        
        &[disabled] {
            color: var(--main-text-and-icons-bg);
            cursor: default;
        }
    }

    > input {
        border: 0;
        padding: 0;
        height: 30px;
        width: 100%;
        text-align: center;
        margin: 0;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &[type='number'] {
            -moz-appearance: textfield;
        }
    }

    > button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 16px;
    }
}
