@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --field-date-range-size: 108px;
}

.FieldDateRange {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;

    .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after,
    .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
        left: calc(var(--field-date-range-size) + 17px);
    }

    &_isDisabled,
    input[disabled] {
        &,
        svg {
            cursor: default;
            opacity: 0.5;
        }
    }

    > span {
        font-size: 23px;
        color: var(--main-icons);
        line-height: 48px;
        bottom: -3px;
        margin: 0 8px;
    }

    .react-datepicker-wrapper {
        width: auto;
        min-width: var(--field-date-range-size);
        margin-top: 0;
    }
}
