@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.HomePage {
    @include desktop {
        padding-bottom: 230px;

        + .Footer,
        + .Newsletter {
            margin-top: -200px;
        }
    }

    #edrone_homepage {
        margin: 0 auto;
        max-width: var(--content-wrapper-width);
        padding-inline: 20px;
        width: 100%;
        overflow-x: hidden;

        &:not(&:empty) {
            padding-block: 24px;
        }

        @include mobile {
            margin: 10px auto 20px;
            padding-block: 0;
        }
    }

    .custom-benefits {
        margin-bottom: -20px;
        margin-top: -10px;

        .SellingTextColor {
            color: $red;

            strong {
                color: $red;
            }
        }

        @include mobile {
            .mgz-element-section {
                max-height: 50px;
            }

            .mgz-element-single_image {
                margin-right: 5px;
                width: 40px;
            }
        }

        .SellingText,
        .SellingTextColor {
            font-size: 16px;
            line-height: 24px;

            @include mobile {
                font-size: 11px;
                font-weight: 500;
                line-height: normal;
            }
        }
    }


    .CmsPage {
        min-height: unset;

        @include mobile {
            margin-top: 0;
        }

        &-Wrapper {
            @include mobile {
                margin-top: 0;
            }
        }

        [data-widget-row="true"] {
            margin: 40px auto;

            @include mobile {
                margin: 25px auto;
            }
        }

        .ProductListsWithSliderWidget-Heading,
        .MultipleProductListWidget {
            .HeadingWidget {
                margin-top: 0;
            }
        }

        .UniqueSellingProposition {
            @include mobile {
                background-color: var(--main-text-and-icons-bg-2);
            }

            @include desktop {
                box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.15);
            }

            .ContentWrapper {
                @include mobile {
                    padding: 0;
                }

                > div,
                > a {
                    @include mobile {
                        padding: 12px;
                        justify-content: center;
                    }
                }
            }
        }
    }

    [dir=ltr] .HomePage {
        .ProductCard {
            &-FigureReview {
                @include desktop {
                    padding-right: 20px;
                    padding-left: 20px;
                }
            }

            &-Content {
                @include mobile {
                    padding-right: 20px;
                    padding-left: 20px;
                }

                @include desktop {
                    padding-right: 25px;
                    padding-left: 25px;
                }
            }
        }
    }
}
