@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.NewsletterPopup {
    @include desktop {
        --popup-min-width: 650px;
        --popup-max-width: 650px;
    }

    @include default-transition(opacity);

    .Popup-Content {
        padding: 16px 20px 36px;

        &::after {
            display: none;
        }
    }

    &-Info {
        text-align: center;
    }

    &-Heading {
        font-weight: 600;
        font-size: 30px;
        line-height: 39px;
        letter-spacing: 0.8px;
        color: $black;

        span {
            font-weight: 700;
            font-size: 55px;
            line-height: 82px;
            text-align: center;
            letter-spacing: 1px;
            color: var(--main-primary);
        }
    }

    &-Text {
        color: $black;
        letter-spacing: 0.3px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin: 25px 0 25px;
    }

    .NewsletterSubscription .Form {
        text-align: left;
        margin-top: 25px;

        input {
            border: 1px solid #e8e8e8;
        }

        .Field-CheckboxLabel {
            color: $black;
            margin-top: 0;

            &:hover {
                color: $black;
            }
        }

        .Button[type='submit'] {
            width: auto;
            max-width: unset;
            margin-top: 0;
        }
    }

    .NewsletterSubscription-SuccessInfoButton {
        border: 1px solid #3bb12f;
        max-width: 426px;
        margin: 24px auto 0;
    }
}
