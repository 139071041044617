@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.GridImages {
    &-Grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
        row-gap: 30px;
    }

    &-ImageContainer {
        overflow: hidden;
        max-height: 150px;

        .Image_ratio_square {
            padding-bottom: 0;
        }

        .Image {
            img {
                width: 100%;
            }
        }
    }
}
