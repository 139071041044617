@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

[dir='ltr'] .Notification {
    --notification-success-color: var(--imported_notificationSuccess_color, var(--semantic-positive));
    --notification-success-background: var(
        --imported_notificationSuccess_background_color,
        var(--semantic-light-positive)
    );
    --notification-error-color: var(--imported_notificationSuccess_color, #dd151e);
    --notification-error-background: var(
        --imported_notificationSuccess_background_color,
        var(--semantic-light-negative)
    );
    --notification-info-color: var(--imported_notificationSuccess_color, #dd151e);
    --notification-info-background: var(
        --imported_notificationSuccess_background_color,
        var(--semantic-light-negative)
    );

    flex-direction: column;
    padding: 0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    border-radius: 4px;

    &-Text {
        padding: 16px 18px;
        margin: 0;
        text-align: center;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        letter-spacing: 0.8px;
    }

    &-ActionButtons {
        display: flex;
        width: 100%;
    }

    &-CloseButton {
        margin-right: 3px;
        max-width: 60px;

        &,
        + a {
            background: var(--notification-button-background);
            height: 40px;
            color: $white;
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            letter-spacing: 0.008em;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
    }

    &-Timer {
        width: 100%;
        height: 5px;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            inset-block-start: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 100%;
            background: var(--notification-color);
            opacity: 0.4;
        }

        &::after {
            content: '';
            position: absolute;
            inset-block-start: 0;
            inset-inline-start: 0;
            width: 0;
            height: 100%;
            background: var(--notification-color);
            animation: shorten calc(var(--animation-lifetime) - var(--animation-duration)) forwards linear;
            animation-delay: var(--animation-duration);
        }
    }

    &_type {
        &_info,
        &_error {
            --notification-button-background: var(--notification-error-color);
        }

        &_success {
            --notification-button-background: var(--notification-success-color);
        }
    }

    @keyframes shorten {
        from {
            width: 0;
        }

        to {
            width: 100%;
        }
    }
}
