@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.CmsPage {
    min-height: 450px;

    &:not(&_isHomePage) {
        @include desktop {
            padding-bottom: 230px;

            + .Footer,
            + .Newsletter {
                margin-top: -200px;
            }
        }
    }

    &-Wrapper {
        margin-block: 0;

        @include mobile {
            margin-block: 14px;
        }

        &_isRulesSubMenu {
            margin-top: 0;
        }

        @include wide-desktop {
            padding-inline: 20px;
        }
    }

    &-Content {
        & > .mgz-element-row {
            margin-inline: -10px;
        }
    }

    &-HeadingContainer {
        padding-bottom: 15px;
    }

    &_id {
        &_ksiegarnie\/nasze-kawiarnie {
            .CmsElementsTabs {
                &-Tabs {
                    @include wide-desktop {
                        justify-content: flex-end;
                        margin: -70px 0 20px;
                    }
                }
            }

            h1, h2, h3 {
                font-weight: 600;
            }
        }

        &_ksiegarnie\/nasze-ksiegarnie {
            .TextInfo {
                font-weight: 400;

                a {
                    font-weight: 400
                }

                .Phone,
                .Email {
                    font-weight: 500;
                }
            }
        }

        &_kariera {
            .PageTittle {
                font-size: 14px;
                line-height: 17px;
                letter-spacing: .5px;
            }
        }

        &_karty-podarunkowe {
            .CmsElementsTabs {
                &-SingleRow {
                    p {
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0.6px;
                    }
                }
            }

            .mgz-icon-wrapper .mgz-icon-element {
                margin-right: 5px;
                top: 0;
                left: 0;
            }
        }

        &_karta-stalego-klienta,
        &_ksiegarnie\/nasze-kawiarnie,
        &_karty-podarunkowe {
            .CmsElementsTabs {
                &-Tabs {
                    height: auto;

                    @include desktop {
                        border-bottom: 0;
                    }
                }

                &-Nav {
                    border-bottom: 0;

                    @include desktop {
                        border-bottom: 1px solid var(--product-slider-tab-nav-border);
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        height: 4px;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background: var(--main-text-and-icons-bg);
                        z-index: 1;

                        @include desktop {
                            display: none;
                        }
                    }
                }

                &-Tab {
                    flex: 0 1 auto;
                    padding: 10px;
                    border-radius: 0;
                    border: 0;
                    color: $black;
                    background-color: $white;
                    letter-spacing: 0.8px;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 1.5;
                    white-space: nowrap;

                    @include mobile {
                        height: 46px;
                    }

                    @include desktop {
                        padding: 18px 12px;
                        border-bottom: 4px solid transparent;
                    }

                    &:not(:last-child) {
                        @include desktop {
                            margin-right: 12px;
                        }
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        height: 4px;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background: transparent;
                        z-index: 2;

                        @include desktop {
                            display: none;
                        }
                    }

                    &_isActive {
                        border-bottom: 0;
                        background-color: $white;
                        color: $black;
                        font-weight: 500;

                        @include desktop {
                            border-bottom: 4px solid var(--product-labels-top);
                        }

                        &::after {
                            background: var(--product-labels-top);
                        }
                    }
                }
            }
        }
    }

    .widget {
        overflow-x: visible;
    }

    .HeadingWidget {
        @include mobile {
            text-align: left;
        }
    }

    .OurCafesTopBanner {
        margin-bottom: 18px;

        img {
            max-width: 925px;
            height: 106px;
            object-fit: cover;
            border-radius: 9px;
        }
    }

    .OurCafes {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
        margin-bottom: 20px;

        @include desktop {
            grid-template-columns: repeat(3, 1fr);
        }

        &-SingleAddress {
            padding: 20px;
            text-align: center;
            border-radius: 9px;
            background-color: $grey;

            p,
            a {
                color: $black;
                margin-bottom: 2px;
            }

            &_TopContent {
                border-bottom: 1px solid var(--main-text-and-icons-bg);
                padding-bottom: 18px;
                margin-bottom: 18px;

                a,
                p {
                    font-weight: 400;
                }
            }

            &_AddressWrapper {
                margin-bottom: 15px;

                > p:first-of-type {
                    font-weight: 500;
                }
            }

            &_BottomContent {
                .OurCafes-SingleAddress_HoursTitle {
                    font-size: 12px;
                    margin-bottom: 12px;
                }
            }
        }
    }

    .ActualPromotionBanner {
        padding: 32px;
        min-height: 180px;
        border-radius: 9px;
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;

        @include desktop {
            display: flex;
            align-items: center;
            column-gap: 40px;
            padding: 32px 110px 32px 32px;
        }

        p,
        a {
            color: $white;
        }

        &--Voucher {
            background-color: var(--main-primary);
        }

        &--Instagram {
            background-color: var(--other-checkout_elements_and_icons);
        }

        &-TopTitle {
            font-size: 24px;
            font-weight: 300;
            line-height: 1;
            letter-spacing: 1.7px;
            margin-bottom: 5px;
        }

        &-Title {
            font-size: 34px;
            font-weight: 700;
            line-height: 1;
            margin-bottom: 10px;
            letter-spacing: 2.4px;
            text-transform: uppercase;
        }

        &-Text {
            font-size: 22px;
            font-weight: 500;
            line-height: 33px;
            letter-spacing: 0.9px;
            margin-bottom: 5px;

            > span {
                font-weight: 700;
            }
        }

        &-Link {
            font-size: 14px;
            font-weight: 300;
            line-height: 1;
            margin-bottom: 0;
            letter-spacing: 1px;
        }

        &-RightIcon {
            position: absolute;
            right: -5px;
            top: -6px;

            &_type {
                &_Hash {
                    top: -12px;
                }
            }
        }
    }

    .OurOffersBanner {
        padding: 15px;
        min-height: 115px;
        border-radius: 9px;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 20px;

        @include desktop {
            padding: 50px;
            min-height: 319px;
            justify-content: center;
        }

        > span {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            img {
                object-fit: cover;
                object-position: 75%;
            }
        }

        p {
            color: $white;
            margin-bottom: 0;
        }

        &-ThinTitle {
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 2.5px;

            @include desktop {
                font-size: 32px;
                line-height: 42px;
            }
        }

        &-BoldTitle {
            font-size: 25px;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: 5px;

            @include desktop {
                font-size: 55px;
                line-height: 82px;
                font-weight: 700;
            }
        }
    }

    .OurCafesDoublePromoBanners {
        display: flex;
        align-items: center;
        column-gap: 20px;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        > a {
            min-width: 295px;
            max-width: 452px;
        }

        &Container {
            h3 {
                margin: 30px 0 0;
                text-align: center;
                font-size: 16px;

                @include desktop {
                    margin: 20px 0 40px;
                    font-size: 25px;
                }
            }

            .CmsElementsTabs {
                &-SingleRow {
                    @include mobile {
                        margin-bottom: 25px;
                    }
                }
            }
        }
    }

    .NewOpeningsWidget {
        &-ContentDetail {
            display: grid;
            grid-template-columns: 40px 1fr;
            align-items: center;
        }
    }

    &-ContentWithMenu {
        .mgz-element-column > .mgz-element-inner {
            padding: 0;
        }

        .mgz-element-row {
            margin-inline: initial;
        }

        @include above-narrow-desktop {
            display: grid;
            grid-template-columns: 315px 925px;
        }

        .CmsPage-SubMenu {
            padding: 0 0;
        }

        .Field {
            .StaticSitesSubmenu {
                width: 100%;
            }
        }
    }

    .CmsStaticSidebarMenu {
        &-Title {
            margin-bottom: 35px;
            margin-top: 5px;
        }

        &-LinkWrapper {
            display: flex;
            align-items: center;
            column-gap: 20px;
            padding-left: 11px;
            border-left: 3px solid transparent;
            margin-left: -14px;

            @include default-transition();

            &:not(:last-of-type) {
                margin-bottom: 20px;
            }

            > a {
                font-size: 14px;
                font-weight: 300;
                letter-spacing: .5px;
                color: var(--main-icons);

                @include default-transition();
            }

            &_active {
                border-left-color: var(--main-primary);

                > a {
                    font-weight: 500;
                    color: $black;
                }
            }
        }
    }

    .GiftCardsActivateCardForm,
    .GiftCardsCheckValidityForm {
        &-Wrapper {
            @include desktop {
                display: grid;
                grid-template-columns: 400px 1fr;
                grid-gap: 85px;
                padding-bottom: 40px;
            }
        }

        &-FormWrapper {
            @include mobile {
                margin-bottom: 35px;
            }
        }

        input {
            width: 100%;
        }

        &-TabTitle {
            margin-bottom: 30px;
        }

        &-AdditionalInfo {
            font-weight: 400;
            color: var(--main-icons);
            margin: 24px 0;
        }

        &-CheckButton {
            min-width: 280px;
        }
    }

    .GiftCardsAndVouchers {
        img {
            margin-bottom: 30px;
        }

        &-Title {
            font-size: 30px;
            font-weight: 600;
            line-height: 1;
            letter-spacing: .8px;
            color: $black;
            margin-bottom: 15px;

            &--color {
                color: var(--main-primary);
            }

            @include mobile {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.6px;
            }
        }

        &-InfoWrapper {
            text-align: center;

            @include desktop {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                align-items: center;
                grid-gap: 30px;
                margin-block-end: 110px;
            }
        }

        &-SingleInfo {
            > svg {
                margin-bottom: 30px;
                margin-right: auto;
            }
        }

        &-SingleInfoText {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: .3px;

            @include mobile {
                margin: 0 57px 48px;
            }
        }
    }

    .RegularClientCardBanner {
        display: grid;
        grid-template-columns: 1fr;
        margin-bottom: 50px;

        @include desktop {
            grid-template-columns: 1fr 1fr;
            align-items: center;
        }

        &-CardWrapper {
            @include mobile {
                order: 2;
            }
        }

        &-TextWrapper {
            @include mobile {
                order: 1;
            }
        }

        &-Text {
            font-size: 30px;
            font-weight: 600;
            line-height: 39px;
            letter-spacing: .8px;
            color: $black;
            max-width: 331px;
            margin-bottom: 0;

            @include mobile {
                font-size: 16px;
                font-weight: 500;
                letter-spacing: .6px;
                text-align: center;
                margin: 0 auto 20px;
            }

            > span:first-child {
                color: var(--main-primary);
            }
        }
    }

    .RegularClientFeatures {
        margin-bottom: 50px;

        &-Title {
            font-size: 30px;
            font-weight: 600;
            line-height: 1;
            letter-spacing: .8px;
            color: $black;
            margin-bottom: 15px;

            &--color {
                color: var(--main-primary);
            }
        }

        &-InfoWrapper {
            text-align: center;

            @include desktop {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                align-items: center;
                grid-gap: 30px;
            }
        }

        &-SingleInfo {
            @include mobile {
                margin-bottom: 35px;
            }

            > svg {
                margin-bottom: 30px;
            }
        }

        &-SingleInfoText {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: .3px;
            line-height: 20px;

            @include mobile {
                max-width: 70%;
                margin: 0 auto 15px;
            }

            > span {
                font-size: 21px;
                font-weight: 500;
                letter-spacing: .5px;
                line-height: 25px;
                color: var(--main-primary);
            }
        }
    }

    .RegularClientDetails {
        padding: 35px;
        border-radius: 9px;
        background-color: $grey;

        @include mobile {
            padding: 15px;
            margin-bottom: 50px;
        }

        &-SingleDetail {
            line-height: 20px;
            padding-left: 15px;

            &::before {
                content: '';
                display: block;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: $black;
                left: -20px;
                top: 11px;
                padding: 0;
            }

            > a {
                font-weight: 300;
                color: var(--categories-bestsellers-accent-color);
            }
        }
    }

    .SeparatorLine {
        width: 100%;
        height: 1px;
        background: var(--main-text-and-icons-bg-2);
    }

    .FamillyCard {
        margin-bottom: 85px;

        @include mobile {
            margin-bottom: 45px;
        }

        &-TitleContet {
            display: flex;
            padding-top: 47px;
            overflow: hidden;

            @include mobile {
                flex-direction: column-reverse;
                padding-top: 24px;
                align-items: center;
            }
        }

        &-ImageContainer {
            max-width: 350px;
            max-height: 220px;
        }

        &-Image {
            max-width: 350px;
            max-height: 220px;
            object-fit: cover;
        }

        &-TextContainer {
            margin-left: 51px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include mobile {
                margin-bottom: 30px;
                margin-left: 0;
                align-items: center;
            }
        }

        &-BlockImage {
            max-width: 85px;
            max-height: 85px;
            object-fit: cover;
        }

        &-Heading {
            color: #DD151E;
        }
    }

    .FamillyCardContent {
        margin-bottom: 70px;

        &-BlockImage {
            width: 85px;
            height: 85px;
            margin-bottom: 30px;
        }

        &-Title {
            font-size: 30px;
            font-weight: 600;
            line-height: 1;
            letter-spacing: .8px;
            color: $black;
            margin-bottom: 15px;

            &--color {
                color: var(--main-primary);
            }
        }

        &-InfoWrapper {
            text-align: center;

            @include desktop {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                align-items: center;
                grid-gap: 30px;
            }
        }

        &-SingleInfo {
            @include mobile {
                margin-bottom: 35px;
            }

            > svg {
                margin-bottom: 30px;
            }
        }

        &-SingleInfoText {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: .3px;
            line-height: 20px;

            @include mobile {
                max-width: 70%;
                margin: 0 auto 15px;
            }

            > span {
                font-size: 21px;
                font-weight: 500;
                letter-spacing: .5px;
                color: var(--main-primary);
                line-height: 25px;
            }
        }
    }

    .FamillyCardDetails {
        padding: 35px;
        border-radius: 9px;
        background-color: $grey;

        @include mobile {
            padding: 15px;
            margin-bottom: 50px;
        }

        &-SingleDetail {
            padding-left: 15px;

            &::before {
                content: '';
                display: block;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: $black;
                left: -20px;
                top: 11px;
                padding: 0;
            }

            > a {
                font-weight: 300;
                color: var(--categories-bestsellers-accent-color);
            }
        }
    }

    hr {
        width: 100%;
        height: 1px;
        margin-bottom: 15px;
        background: var(--main-text-and-icons-bg);
    }
}
