@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.HeaderHelpOverlay {
    top: calc(var(--header-nav-height) - 35px);
    left: 50%;
    width: 220px;
    background: #ffffff;
    border: 1px solid transparent;
    border-radius: 9.5px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    transform: translateX(-40%);

    > div {
        border-radius: 9.5px;
    }

    &-Triangle {
        position: absolute;
        top: -10px;
        left: 40%;
        transform: translateX(-50%);
        filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.15));
        background-color: transparent;
    }
}
