@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

[dir="ltr"] .ProductAttributeValue {
    width: 100%;

    .Field-Wrapper_type_checkbox {
        .ProductAttributeValue-Text {
            margin-right: 0;
    
            .Field-CheckboxLabel {
                @include desktop {
                    width: 100%;
                    flex-direction: row-reverse;
                    justify-content: space-between;
                    align-items: center;
                }
            }
    
            .input-control {
                border: 1px solid var(--main-text-and-icons-not-active);
                align-self: center;

                @include desktop {
                    margin: 0;
                }
            }

            .label {
                line-height: unset;
                align-items: center;
            }
        }

        .ProductAttributeValue-Label {
            font-size: 14px;
            font-weight: 300;
            color: var(--category-tree-category-color);
            padding-right: 5px;
            position: relative;
            display: flex;
            grid-column-gap: 5px;
            column-gap: 5px;
            align-items: center;
            text-align: left;
        }

        .ProductAttributeValue-SubLabel {
            font-size: 10px;
            font-weight: 300;
            line-height: 15px;
            color: var(--category-count-color);
            border: 1px solid rgba(62, 61, 61, 0.3);
            border-radius: 2.5px;
            height: 15px;
            margin: 0;
            padding: 0 3px;
        }
    }
}
