@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.ShowMoreLessContent {
    &-Wrapper {
        .ShowMoreLessContent {
            @include disable-select;

            @include mobile {
                &-Content {
                    & > div {
                        display: initial;
                    }
                }
            }

            &-Button {
                cursor: pointer;
                width: 100%;
                display: block;
                margin-top: 15px;
                font-size: 12px;
                font-weight: 400;
                color: $black;
                position: relative;        
                min-width: auto;
                text-align: left;

                &::before {
                    position: absolute;
                    top: 50%;
                    left: 90px;
                    content: url('../../assets/icons/small-arrow-right.svg');
                    line-height: 1;
                    transform: translate(-50%, -50%) rotate(90deg);
                    transition: .3s;
                }

                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 80px;
                    background: linear-gradient(to bottom, rgba(255,255,255,0.001) 0%, white 100%);
                    position: absolute;
                    bottom: 35px;
                    left: 0;
                }
            }
        }

        &_isExpanded {
            .ShowMoreLessContent-Button {
                &::before {
                    transform: translate(-50%, -50%) rotate(270deg);
                    transition: .3s;
                }
    
                &::after {
                    display: none;
                }
            }
        }
    }
}
