@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.RulesCtaWidget {
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    background-color: $grey;
    border-radius: 10px;
    padding: 14px 20px;
    margin-bottom: 15px;
    min-height: 60px;

    &-Title,
    > a {
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: .5px;
        color: $black;
        margin: 0;
    }

    > a {
        height: 90%;
        display: flex;
        align-items: center;
        font-weight: 400;

        &:hover {
            color: var(--main-primary);
        }

        &:not(:last-of-type) {
            padding-right: 20px;
            margin-right: 20px;
            border-right: 1px solid var(--placeholder-gradient-color);
        }
    }

    > .RulesCtaWidget-Download {
        display: grid;
        grid-template-columns: 20px 1fr;
        grid-gap: 15px;

        &:hover {
            > svg {
                > path {
                    fill: var(--primary-base-color);
                }
            }
        }
    }

    > .RulesCtaWidget-Read {
        &::after {
            content: '>';
            display: inline-block;
            font-size: 18px;
            margin-left: 15px;
        }
    }

    &_isMobile {
        grid-template-columns: 1fr auto;
        grid-column-gap: 15px;

        > .RulesCtaWidget-Title {
            text-align: left;
            font-size: 12px;
            line-height: 1.2;
        }

        a {
            &:first-of-type {
                margin: 0 0 0 18px;
                border-right: none;
                border-left: 1px solid var(--placeholder-gradient-color);
            }
        }
    }
}
