@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.GiftCard {
    overflow: hidden;
    position: relative;
    min-height: 250px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1.8fr;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    background: linear-gradient(123deg, #6B6B6B 30.49%, #A3A3A3 93.08%);
    border-radius: 9px;

    &_success {
        box-shadow: 0px 10px 20px rgba(221, 57, 57, 0.38);
        background: linear-gradient(123deg, #DD151E 30.49%, #FC6B01 93.08%);
    }

    > svg {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    &-BalanceWrapper,
    &-DetailsWrapper {
        padding: 30px 18px;
    }

    &-BalanceWrapper {
        position: relative;

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, .1);
        }
    }

    &-BalanceValue {
        font-weight: 600;
        line-height: 1;
        letter-spacing: .7px;
        margin-bottom: 0;
        color: $white;

        &_value {
            font-size: 32px;
        }

        &_decimalValue {
            font-size: 18px;
        }
    }

    &-DetailsWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &-SingleDetailWrapper {
        display: flex;
        align-items: center;
        column-gap: 8px;
        margin-bottom: 12px;
    }

    &-CardLabel {
        font-size: 12px;
        font-weight: 400;
        line-height: 1;
        letter-spacing: .2px;
        color: rgba(255, 255, 255, .7);
        margin-bottom: 0;
        
        &_balance {
            margin-bottom: 5px;
        }
    }

    &-DetailsValue {
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: .5px;
        color: $white;
        margin-bottom: 0;
    }
}
