@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.Breadcrumb {
    --breadcrumbs-color: var(--main-text-and-icons_2);

    &:last-child {
        svg {
            display: none;
        }
    }

    &-Link {
        display: inline-flex;
        align-items: center;
        text-transform: uppercase;
        letter-spacing: 0.2px;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;

        svg {
            margin: 0 8px 0 20px;
            
            path {
                fill: var(--breadcrumbs-color);
            }
        }
    }
}
