@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.Overlay {
    &:not(.Overlay_isStatic) {
        @include mobile {
            inset-block-start: calc(var(--header-total-height));
        }
    }
}
