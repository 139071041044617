@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.CheckoutDeclarationPopup {
    &-Content {
        @include mobile {
            height: 100%;
            border-radius: 0;
            padding-top: 30px;

            .Popup-CloseBtn {
                display: none;
            }
        }

        @include desktop {
            --popup-content-padding: 30px;

            padding: 56px var(--popup-content-padding) 30px;
        }

        .Popup-Heading,
        &::after {
            display: none;
        }
    }

    &-Heading {
        margin-top: 0;
        margin-bottom: 24px;
        text-align: center;

        span {
            color: $red;
        }
    }

    &-Description {
        margin-bottom: 32px;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        text-align: center;
    }

    &-Options {
        display: flex;
        align-items: stretch;

        @include desktop {
            column-gap: 22px;
        }

        @include mobile {
            flex-direction: column;
            row-gap: 22px;
        }
    }

    &-Option {
        flex: 1;
        padding: 27px 20px;
        text-align: center;
        border-radius: 9px;

        &_type {
            &_agree {
                background-color: lighten($default-green-color, 45%);

                .CheckoutDeclarationPopup-Option {
                    &Heading, &Description span {
                        color: $default-green-color;
                    }

                    &Button {
                        border-color: $default-green-color;

                        &:hover, &:focus {
                            background-color: $default-green-color;
                            color: $white;
                        }
                    }
                }
            }

            &_disagree {
                background-color: lighten($red, 45%);

                .CheckoutDeclarationPopup-Option {
                    &Heading, &Description span {
                        color: $red
                    }

                    &Button {
                        border-color: $red;

                        &:hover, &:focus {
                            background-color: $red;
                            color: $white;
                        }
                    }
                }
            }
        }

        &Heading {
            margin-block: 0;
            font-size: 12px;
            font-weight: 500;
            line-height: 17px;
        }

        &Button {
            margin-block: 15px 17px;
            padding: 18px;
            width: 100%;
            background-color: $white;
            border-radius: 100px;
            border: 1px solid transparent;
            overflow: hidden;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;

            @include default-transition();
        }

        &Description {
            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
            text-align: center;
            margin-block: 0;
        }
    }
}
