@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.ShowMoreLessText {
    position: relative;
    
    @include disable-select();

    &:not(&_isSimpleVersion) {
        .ShowMoreLessText-ToggleLink {
            display: block;
            margin-top: 15px;
            font-size: 12px;
            font-weight: 400;
            color: $black;
            position: relative;

            &::before {
                position: absolute;
                top: 50%;
                left: 90px;
                content: url('../../assets/icons/small-arrow-right.svg');
                line-height: 1;
                transform: translate(-50%, -50%) rotate(90deg);
                transition: .3s;
            }

            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 80px;
                background: linear-gradient(to bottom, rgba(255,255,255,0.001) 0%, white 100%);
                position: absolute;
                bottom: 35px;
                left: 0;
            }

            &_IsExpanded {
                &::before {
                    transform: translate(-50%, -50%) rotate(270deg);
                    transition: .3s;
                }

                &::after {
                    display: none;
                }
            }

            @include mobile {
                text-align: center;
            }
        }
    }
}
