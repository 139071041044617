@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/mixins';
@import '../../../../../../../src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

$dot-background: $white;
$dot-background-active: var(--main-primary);
$slider-height-mobile: 228px;
$slider-height-tablet: 337px;
$slider-height-desktop: 398px;

@include keyframes-fadeInLeft();

.AdvoxSlider {
    background-color: $white;

    @include wide-desktop {
        max-width: calc(var(--content-wrapper-width) + 100px);
        margin: 0 auto;
    }

    &-Placeholder {
        @include placeholder($slider-height-mobile);

        @include tablet {
            @include placeholder($slider-height-tablet);
        }

        @include desktop {
            @include placeholder($slider-height-tablet);
        }

        @include wide-desktop {
            @include placeholder($slider-height-desktop);
        }
    }

    &,
    .slick-slider,
    .slick-slide img {
        overflow: hidden;
    }

    @include desktop {
        border-top: 4px solid Var(--main-primary);
    }

    .SliderPrevArrow {
        @include wide-desktop {
            left: 295px;
        }

        @include widest-desktop {
            left: 330px;
        }
    }

    .SliderNextArrow {
        @include wide-desktop {
            right: 10px;
        }

        @include widest-desktop {
            right: 20px;
        }
    }

    &-Slide {
        &_hasAdditionalText {
            .AdvoxSlider-SlideAdditionalText {
                display: none;

                @include tablet {
                    display: flex;
                }

                @include desktop {
                    display: flex;
                }

                @include widest-desktop {
                    margin-left: 50px;
                }
            }

            .AdvoxSlider-Image {
                img {
                    @include tablet {
                        max-width: calc(100% - 260px);
                        margin-left: 260px;
                    }

                    @include above-narrow-desktop {
                        max-width: calc(100% - 265px);
                        margin-left: 265px;
                        margin-right: 10px;
                    }

                    @include wide-desktop {
                        max-width: 1000px;
                        margin-left: auto;
                        margin-right: 10px;
                        border-radius: 10px;
                    }

                    @include widest-desktop {
                        max-width: 1050px;
                    }
                }
            }

            .AdvoxSlider-Image_isTruncated {
                img {
                    @include tablet {
                        max-width: 555px;
                        margin-left: auto;
                        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 12% 100%);
                    }

                    @include desktop {
                        max-width: calc(100% - 210px);
                        margin-left: 210px;
                        margin-right: 10px;
                        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 12% 100%);
                    }

                    @include wide-desktop {
                        -webkit-clip-path: url(#heroSliderClipMaskId);
                        clip-path: url(#heroSliderClipMaskId);
                        max-width: calc(100% - 220px);
                        margin-left: 210px;
                        border-radius: 0;
                    }

                    @include widest-desktop {
                        max-width: 1090px;
                        margin-left: 280px;
                    }
                }
            }
        }
    }

    &-SlideAdditionalText {
        background-color: $white;
        padding: 14px 0 20px 14px;
        min-width: 210px;
        max-width: 210px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 3;
        padding-inline: 32px;
        align-items: center;

        @include mobile {
            padding-inline: 14px;
        }

        @include desktop {
            padding-inline: 32px;
        }

        @include wide-desktop {
            padding-inline: 20px;
        }

        h2,
        h3,
        h4,
        h5,
        h6 {
            font-weight: 500;
            font-size: 17px;
            line-height: 26px;
            letter-spacing: 0.8px;
            color: $black;
            margin-bottom: 12px;
            min-width: 228px;
            margin-top: 0;

            &::after {
                content: '';
                position: absolute;
                bottom: -2px;
                left: 0;
                height: 2px;
                background-color: var(--main-primary);
                width: 97px;
            }
        }

        ul {
            margin-bottom: 30px;

            &:last-of-type {
                margin-bottom: 0;
            }

            li a {
                color: $black;
                font-weight: 300;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.6px;
            }
        }

        > div > * {
            opacity: 0;
            transition: all 0.75s cubic-bezier(0.47, 0, 0.745, 0.715);
        }
    }

    .slick-active.slick-current {
        .AdvoxSlider-SlideAdditionalText {
            > div > * {
                @include animation(fadeInLeft);

                &:nth-child(1) {
                    @include animation-delay (1s);
                }

                &:nth-child(2) {
                    @include animation-delay (1.5s);
                }

                &:nth-child(3) {
                    @include animation-delay (2s);
                }

                &:nth-child(4) {
                    @include animation-delay (2.5s);
                }
            }
        }
    }

    &-Image {
        display: block;
        padding-top: 56.25%;
        width: 100%;

        @include tablet {
            padding-top: 32.95%;
        }

        @include above-narrow-desktop {
            padding-top: 28.84%;
        }

        img {
            object-fit: cover;
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;

            @include wide-desktop {
                padding-top: 15px;
                padding-bottom: 15px;
                object-fit: unset;
            }
        }
    }

    &-Button {
        margin-block-start: 16px;
        color: $white;
        border-color: $white;
    }

    ul.slick-dots {
        position: absolute;
        inset-block-end: 10px;
        height: 32px;
        width: calc(100% - 210px);
        left: 210px;

        @include mobile {
            inset-block-end: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        @include tablet {
            left: 210px;
            transform: unset;
            inset-block-end: 10px;
        }

        @include wide-desktop {
            inset-block-end: 20px;
            width: calc(100% - 220px);
            left: 210px;
        }

        @include widest-desktop {
            width: 1090px;
            left: 280px;
        }

        li {
            background: $dot-background;
            width: 12px;
            height: 12px;
            border-radius: 100%;

            &.slick-active {
                background: $dot-background-active;
            }
        }
    }
}
