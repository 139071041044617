@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --fail-info-background-color: #FFAE1B;
    --success-info-background-color: #3BBB76;
}

.GiftCardsActivateCardForm {
    &-ActivateInfo {
        display: grid;
        grid-template-columns: 16px 1fr;
        grid-gap: 8px;
        padding: 7px 14px;
        border-radius: 16px;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0.2px;
        color: $white;
        margin-bottom: 0;
        margin-top: 15px;

        &_fail {
            background-color: var(--fail-info-background-color);            
        }

        &_success {
            background-color: var(--success-info-background-color);
        }

        > svg {
            position: relative;
            top: 3px;
        }
    }
}
