@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --header-total-height: calc(var(--header-height) + var(--offline-notice-height) + var(--demo-notice-height) + var(--top-bar-height, 0px) + env(safe-area-inset-top));

    @include desktop {
        --header-top-menu-height: 38px
    }

    @include tablet {
        --header-height: 60px;
    }
}

@mixin button-visible {
    opacity: 1;
    height: 25px;
    width: 25px;
    pointer-events: all;
    overflow: visible;
    cursor: pointer;
}

@mixin logo-visible {
    opacity: 1;
    max-width: 100%;
}

[dir='ltr'] .Header {
    --icon-button-size: 40px;

    border-block-end: 0;

    @include mobile-and-tablet {
        box-shadow: -5px 2px 4px rgba(231, 231, 231, .5);
    }

    .TopBarWidget {
        max-width: 100%;
    }

    &-MyAccountName {
        white-space: nowrap;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
        max-width: 50px;
    }

    &-NavClose {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        width: 16px;
        height: 16px;
        z-index: 999999;
    }

    &-Title {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        a {
            color: currentColor;
            display: inherit;
            align-items: inherit;
            justify-content: inherit;
            gap: inherit;
            font-weight: inherit;
            font-size: inherit;
            pointer-events: auto;
        }

        img {
            width: auto;
            height: auto;
        }

        @include mobile-and-tablet {
            letter-spacing: 0.2px;
            font-weight: 500;
            font-size: 14px;
        }

        &_isVisible {
            @include mobile-and-tablet {
                opacity: 1;
                max-width: 70%;
            }

            span {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    &-IconsWrapper {
        align-items: center;
        justify-content: space-between;

        @include tablet {
            display: none;
        }

        > div {
            align-items: center;
            justify-content: space-between;
            margin: 0;

            &,
            > div,
            > div > a,
            .Header-MyAccountWrapper,
            .Header-MinicartButtonWrapper {
                @include desktop {
                    width: var(--icon-button-size);
                    min-height: 53px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0;
                }
            }
        }

        button,
        a {
            &:hover {
                @include desktop {
                    > svg {
                        path {
                            fill: var(--main-primary);
                        }
                    }

                    > span:last-of-type {
                        color: var(--main-primary);
                    }
                }
            }

            &.Header-HelpWrapper {
                &:hover {
                    @include desktop {
                        > svg {
                            path {
                                stroke: var(--main-primary);
                            }
                        }
                    }
                }
            }
        }
    }

    &-Button {
        &_isVisible {
            @include mobile-and-tablet {
                @include button-visible;
            }
        }

        &_type {
            &_menu,
            &_minicart {
                @include tablet {
                    margin-left: auto;
                }
            }

            &_wishlist {
                @include button-visible;

                color: $black;
            }

            &_close {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 20px;
                width: 16px;
                height: 16px;
                z-index: 999999;
            }

            &_back {
                display: flex;
                align-items: center;
                position: absolute;
                left: 16px;
                width: 20px;
            }
        }
    }

    &-LogoWrapper {
        z-index: 1;
        margin: 0;

        @include mobile {
            --header-logo-width: 146px;
            --header-logo-height: 23px;
        }

        @include tablet {
            --header-logo-width: 162px;
            --header-logo-height: 26px;

            position: static;
            opacity: 0;
            max-width: 0;
        }

        @include desktop {
            --header-logo-width: 245px;
            --header-logo-height: 39px;
        }

        &_isVisible {
            @include tablet {
                @include logo-visible;
            }
        }
    }

    &-NavWrapper {
        max-width: 100%;
        background-color: $white;
    }

    &-Nav {
        background-color: $white;

        @include tablet {
            display: flex;
            // stylelint-disable-next-line
            grid-template-columns:
                minmax(var(--header-logo-width), 1fr) minmax(auto, 660px)
                1fr;
            justify-content: unset;
            padding-inline: 14px;
        }

        @include above-narrow-desktop {
            --header-nav-height: 100px;

            // stylelint-disable-next-line
            grid-template-columns:
                minmax(100px, var(--header-logo-width)) minmax(auto, 2fr)
                minmax(100px, 250px);
            padding-inline: 32px;
        }

        @include mobile {
            flex-wrap: wrap;
        }

        @include wide-desktop {
            padding-inline: 20px;
        }
    }

    &_name {
        &_menu_subcategory,
        &_popup,
        &_customer_account,
        &_customer_sub_account,
        &_customer_account_menu,
        &_wishlist,
        &_customer_account_page,
        &_pdp {
            .SearchField {
                @include mobile-and-tablet {
                    display: none;
                }
            }
        }

        &_customer_account_menu,
        &_customer_account_page,
        &_menu_subcategory {
            @include mobile-and-tablet {
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 2px;
                    width: 100%;
                    background-color: var(--main-primary);
                }
            }
        }

        &_default {
            .Header-LogoWrapper {
                &_isActive {
                    @include mobile {
                        width: 100%;
                        height: var(--header-nav-height);
                        padding: 0;
                        display: flex;
                        align-items: center;
                    }

                    > div {
                        @include mobile {
                            max-width: var(--header-logo-width);
                            max-height: var(--header-logo-height);
                        }
                    }
                }
            }
        }

        &_category,
        &_filter,
        &_wishlist {
            @include mobile {
                border-bottom: 2px solid var(--main-primary);
            }
        }

        &_category {
            &_tree {
                &_overlay {
                    @include mobile {
                        border-bottom: 2px solid var(--main-primary);
                    }
                }
            }
        }

        &_search {
            @include default-transition();

            @include above-narrow-desktop {
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100vh;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background: rgba(0, 0, 0, .5);
                    z-index: -1;

                    @include default-transition();
                }
            }
        }

        &_pdp {
            .Header-Title {
                @include mobile {
                    max-width: 65%;
                }
            }
        }
    }

    &-Wishlist,
    &-Minicart {
        &ItemCount {
            background: var(--primary-base-color);
            border-radius: 8px;
            color: var(--color-white);
            height: 17px;
            padding: 2px;
            position: absolute;
            min-width: 17px;
            display: flex;
            font-size: 11px;
            justify-content: center;
            align-items: center;
            font-weight: 700;

            @include mobile {
                inset-block-start: -7px;
                inset-inline-end: -11px;
            }

            @include desktop {
                top: 0;
                right: 2px;
            }
        }
    }

    &-MyAccount {
        @include desktop {
            height: var(--icon-button-size);
        }
    }

    &-HelpWrapper,
    &-WishlistButtonWrapper,
    &-MinicartButtonWrapper,
    &-MyAccountWrapper {
        &,
        > a,
        > button {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            @include desktop {
                // stylelint-disable-next-line
                min-height: 53px;
                min-width: 40px;
            }
        }

        span {
            font-weight: 400;
            font-size: 11px;
            line-height: 14px;
            letter-spacing: 0.2px;
            margin-top: 12px;
        }
    }

    &-Wrapper {
        &_isCheckout {
            @include above-narrow-desktop {
                --header-nav-height: calc(100px + var(--top-bar-height, 0px));
                --header-height: calc(var(--header-nav-height));
            }

            --header-total-height: calc(var(--header-height) + var(--offline-notice-height) + var(--demo-notice-height) + env(safe-area-inset-top));
        }

        &_isSearchField {
            @include mobile {
                --header-height: calc(80px + var(--top-bar-height, 0px));
                --header-total-height: calc(var(--header-height) + var(--offline-notice-height) + var(--demo-notice-height) + env(safe-area-inset-top));
            }
        }
    }

    &_isCheckout {
        .Header {
            &-NavWrapper {
                @include mobile {
                    position: relative;

                    &::after {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 2px;
                        top: calc(100% - 1px);
                        left: 0;
                        background: $red;
                    }
                }
            }

            &-IconsWrapper {
                justify-content: flex-end;
                grid-gap: 27px;
            }
        }
    }
}
