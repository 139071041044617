@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.ScrollToTop {
    --scroll-to-top-size-mobile: 52px;
    --scroll-to-top-size: 60px;
    --scroll-to-top-bg: #4847FF;
    --scroll-to-top-bottom: calc(var(--footer-nav-height) + 24px);

    position: fixed;
    z-index: 9;
    right: 30px;
    bottom: 30px;
    width: var(--scroll-to-top-size);
    height: var(--scroll-to-top-size);
    background: var(--scroll-to-top-bg);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.281769);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transform: translateY(50px);
    
    @include default-transition();

    &_isVisible {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }

    &_isCart {
        --scroll-to-top-bottom: calc(var(--footer-totals-mobile-height) + var(--advox-free-delivery-height) + 55px + 34px);
    }

    @include tablet {
        width: var(--scroll-to-top-size-mobile);
        height: var(--scroll-to-top-size-mobile);
        bottom: var(--scroll-to-top-bottom);
        right: 20px;
    }

    @include mobile {
        width: var(--scroll-to-top-size-mobile);
        height: var(--scroll-to-top-size-mobile);
        bottom: var(--scroll-to-top-bottom);
        right: 20px;
    }
}
