@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.AdditionalInformation {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    border-top: 1px solid var(--main-text-and-icons-bg); 
    min-height: 48px;

    @include mobile {
        justify-content: center;
        padding: 0px
    }

    &_isClicable {
        cursor: pointer;
    }

    > svg {
        margin-right: 20px;
        width: 22px;

        path {
            fill: var(--main-primary);
        }
    }

    &-IconValue {
        .ChevronIcon {
            right: 0;
        }

        > svg {
            path {
                fill: black
            }
        } 
    }

    &-Title {
        font-size: 12px;
        font-weight: 400;
        margin-right: 5px;
        color: $black;
    }

    &-Value {
        font-size: 12px;
        font-weight: 600;
        color: $black;
    }
}

.ProductActions {
    .ChevronIcon {
        right: 0
    }
}
