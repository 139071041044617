@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/mixins';
@import '../../style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

.NewsletterSubscription {
    --newsletter-subscription-placeholder-color: #000000;
    --newsletter-subscription-max-width: 516px;

    max-width: var(--newsletter-subscription-max-width);
    margin: 0 auto;

    .Field-Wrapper:not(:first-of-type) {
        margin-top: 15px;

        @include desktop {
            margin-top: 18px;
        }
    }

    &-SuccessInfoButton,
    .FieldForm-Fieldset .Field input {
        border-radius: var(--default-button-radius);
        height: var(--medium-button-height);
        border-color: transparent;
    }

    .FieldForm-Fieldset .Field {
        input {
            padding: 17px 15px 17px 15px;

            @include desktop {
                padding: 17px 15px 17px 27px;
            }

            &[placeholder] {
                text-overflow: ellipsis;
            }

            &::placeholder {
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 0.2px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                @include desktop {
                    opacity: 1;
                }
            }
        }

        &_type_email {
            input {
                padding-right: 155px;

                @include mobile {
                    padding-right: 115px;
                }
            }
        }
    }

    .Field-CheckboxLabel {
        color: #8A8A8A;
        margin-top: 0;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;

        span {
            z-index: 1;
        }

        @include desktop {
            padding-right: 25px;
            font-weight: 400;
        }

        @include wide-desktop {
            padding-right: 95px;
        }

        &:hover {
            color: #8A8A8A;
        }

        @include mobile {
            color: $black;

            &:hover {
                color: $black;
            }
        }
    }

    &-SuccessInfoButton {
        background: $white;
        color: #3bb12f;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.6px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: var(--newsletter-subscription-max-width);

        svg {
            margin-right: 10px;

            path {
                fill: #3bb12f;
            }
        }
    }

    &-SuccessInfoText {
        margin: 16px 0 0 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.6px;
        color: $black;
        padding: 0 40px;
    }

    .Button {
        position: absolute;
        top: 0;
        right: -1px;
        margin: 0;
        width: auto;

        @include mobile {
            min-width: 115px;
            padding-left: 10px;
            padding-right: 10px;
        }

        @include desktop {
            min-width: 156px;
        }
    }
}
