$white: #ffffff;
$black: #000000;
$gray-light: #E8E8E8;
$grey: #f5f5f5;
$grey3: #8A8A8A;
$gray2: #D9D9D9;
$grey4: #B9B9B9;
$orange: #f26522;
$red: #dd151e;
$violet: #4847ff;
$default-primary-base-color: #dd151e;
$default-primary-dark-color: #b20008;
$default-primary-light-color: lighten($default-primary-base-color, 15%);
$default-secondary-base-color: #eee;
$default-secondary-dark-color: darken($default-secondary-base-color, 15%);
$default-secondary-light-color: lighten($default-secondary-base-color, 15%);
$font-muli: 'Muli', sans-serif;
$font-poppins: 'Poppins', sans-serif;
$font-standard-size: 62.5%;
$default-green-color: #3BBB76;
$orlen-paczka-color: #C20E1A;
$default-green-dark-color: darken($default-green-color, 15);
